import React, { useState, useEffect } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isMobile } from "react-device-detect";
import moment from "moment";

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import EditIcon from '@material-ui/icons/Edit';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import PrintIcon from '@material-ui/icons/Print';
import ClearIcon from "@material-ui/icons/Clear";

import CustomEnhancedTable from "../../commons/CustomEnhancedTable";
import CustomAlert from "../../commons/CustomAlert";
import CustomPrompt from "../../commons/CustomPrompt";

import agendaTurnActions from "../../../actions/agendaTurn";
import agendaTurnStateActions from "../../../actions/agendaTurnState";
import appointmentStateActions from "../../../actions/appointmentState";
import appointmentActions from "../../../actions/appointment";
import { setSnack } from "../../../actions/globals";

import { CircularProgress, Chip } from "@material-ui/core";
import Filter from "@material-ui/icons/Filter";
import FilterTurnsByPatient from "./FilterTurnsByPatient";
import Appointment from "./Appointment";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      padding: theme.spacing(2)
    },
    row: {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    }
  };
});

const TurnActions = (props) => {
  const startDatetime = moment.utc(props.row.startDatetime, "YYYY-MM-DD HH:mm:ss").local();
  const endDatetime = moment.utc(props.row.endDatetime, "YYYY-MM-DD HH:mm:ss").local();
  const now = moment();
  const showCancel = endDatetime > now && now < startDatetime.subtract(3, "days");
  return (
    <>
    { showCancel &&
      <IconButton
        size="small"
        onClick={(event) => {
          event.stopPropagation()
          return props.handleCancelAppointment(props.row);
        }}
      >
        <ClearIcon />
      </IconButton>
    }
    </>
  );
}

const TurnDetail = (props) => {
  const classes = useStyles();
  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={4}>
        ID: { props.row._id }
      </Grid>
      { props.row.appointment &&
        <Grid item xs={4}>
          Tipo de examen: { props.row.appointment.examType.name }
        </Grid>
      }
    </Grid>
  );
}

const TurnsList = (props) => {

  const classes = useStyles();
  const [ inTurns, setInTurns ] = useState([]);
  const [ appointmentParams, setAppointmentParams ] = useState(null);
  const [ isLockCheckable, setIsLockCheckable ] = useState(false);
  const [ isUnlockCheckable, setIsUnlockCheckable ] = useState(false);
  const [ turnsSelected, setTurnsSelected ] = useState([]);
  const [ alert, setAlert ] = useState({ open: false, title: "", content: "" });
  const [ promptData, setPromptData ] = useState({ okAction: null, okParams: [], open: false, title: "", content: "" });

  useEffect(() => {
    
    const timeInterval = 10*60*1000; // 10 minutes * 60 seconds * 1000 mili seconds
    const clearFormTimer = setInterval(() => {
      return props.resetTurns();
    }, timeInterval);
    return () => {
      clearInterval(clearFormTimer);
    }
    
  }, []);

  useEffect(() => {
    if(props.appointmentStates.length){
      const appointStates = props.appointmentStates.filter(appointmentState => (appointmentState.key === "assigned" || appointmentState.key === "confirmed")).map(appointmentState => appointmentState._id);
      let query = `?appointmentState=${appointStates}&startDatetime>${moment.utc()}&sort=startDatetime`;
      props.getAppointmentsByQuery(query);
    } else {
      props.fetchAppointmentStatesIfNeeded("?").then(resp => {
        const appointStates = props.appointmentStates.filter(appointmentState => (appointmentState.key === "assigned" || appointmentState.key === "confirmed")).map(appointmentState => appointmentState._id);
        let query = `?appointmentState=${appointStates}&startDatetime>${moment.utc()}&sort=startDatetime`;
        props.getAppointmentsByQuery(query);
      });
    }
    props.fetchAgendaTurnStatesIfNeeded("?");
    if(props.location.state){
      const { appointment, turn, from } = props.location.state;
      if(from === "createPatient"){
        if(appointment){
          setAppointmentParams({
            open: true,
            turn: appointment.agendaTurns[0],
            action: "edit",
            modelToEdit: appointment
          });
        } else {
          setAppointmentParams({
            open: true,
            turn: turn,
            action: "add",
            modelToEdit: null
          });
        }
      }
    }
  }, []);

  useEffect(() => {
    if(props.appointments){
      const items = props.appointments.map((appointment) => {
        const patientAge = moment.utc().local().diff(moment.utc(appointment.patientBirthdate, "YYYY-MM-DD HH:mm:ss").local(), "years");
        const patientBirthdate = moment.utc(appointment.patientBirthdate, "YYYY-MM-DD HH:mm:ss").local().format("DD/MM/YYYY");
        appointment.startDateF = moment.utc(appointment.startDatetime, "YYYY-MM-DD HH:mm:ss").local().format("DD/MM/YYYY");
        appointment.endDateF = moment.utc(appointment.endDatetime, "YYYY-MM-DD HH:mm:ss").local().format("DD/MM/YYYY");
        appointment.startTimeF = moment.utc(appointment.startDatetime, "YYYY-MM-DD HH:mm:ss").local().format("HH:mm");
        appointment.endTimeF = moment.utc(appointment.endDatetime, "YYYY-MM-DD HH:mm:ss").local().format("HH:mm");
        appointment.appointmentPatientNameF = `${appointment.patientLastname}, ${appointment.patientName}`;
        appointment.appointmentPatientAgeF = patientAge ? patientAge : "";
        appointment.appointmentPatientBirthdateF = patientBirthdate ? patientBirthdate : "";
        appointment.resourceNameF = appointment.agendaTurns[0] ? appointment.agendaTurns[0].agenda.resource.name : "N/A";
        appointment.agendaTurnStateF = appointment.appointmentState.name;
        appointment.durationF = `${appointment.duration} minutos`;
        let isCheckable = true;
        appointment.isCheckable = isCheckable;
        return appointment;
      }).filter(appointment => appointment.isCheckable);
      setInTurns(items);
    }
  }, [props.appointments, isLockCheckable, isUnlockCheckable])


  const handleCancelAppointment = (appointment) => {
    const { _id: appointmentId } = appointment;
    const appointmentState = props.appointmentStates.find(appointmentState => appointmentState.key === "cancelled");
    if(appointmentState){
      setPromptData({
        okAction: handleCancelAppointmentConfirm,
        okParams: [{ appointmentState: appointmentState._id, agendaTurns: [] }, appointmentId],
        open: true,
        title: "Cancelar asignación",
        content: <Typography>{`¿Desea cancelar la asignación?`}</Typography>
      });
    } else {
      props.fetchAppointmentStatesIfNeeded("?");
      setAlert({ open: true, title: "Error", content: "Ocurrió un error de conexión, por favor intente nuevamente" });
    }
  }

  const handleCancelAppointmentConfirm = (params, id) => {
    props.updateAppointment(params, id).then(resp => handleClose());
  }

  const handleAppointment = (agendaTurn) => {

    if(agendaTurn.appointment){

      setAppointmentParams({
        open: true,
        turn: agendaTurn,
        action: agendaTurn.appointment ? "edit" : "add",
        modelToEdit: agendaTurn.appointment
      });

    } else {

      const { startDatetime, agenda } = agendaTurn;
      const startDatetimeQ = moment.utc(startDatetime, "YYYY-MM-DD HH:mm:ss");
      const endDatetimeQ = moment.utc(startDatetime, "YYYY-MM-DD HH:mm:ss");
      const query = `?agenda=${agenda._id}&startDatetime=${startDatetimeQ}&sort=startDatetime`;

      props.checkIfTurnsAreFree(query).then(resp => {
        const { models: turns } = resp.value;
        let isNotFree = turns.map(turn => {
          const notfree = turn.agendaTurnState.key !== "free";
          return notfree;
        });

        if(isNotFree.includes(true)){
          props.setSnack({ open: true, severity: "error", message: "El turno está asignado o bloqueado, por favor actualice el listado y seleccione uno libre" });
          return handleClose();
        } else {
          setAppointmentParams({
            open: true,
            turn: agendaTurn,
            action: agendaTurn.appointment ? "edit" : "add",
            modelToEdit: agendaTurn.appointment
          });
        }
      });
    }
  }

  const handleTurnSelected = (selected) => {
    setTurnsSelected(selected.map(item => item.item));
  }

  let headersWithActions = (isLockCheckable || isUnlockCheckable) ? [] : [{ name: "Acciones",
    accessor: null,
    type: "component",
    Component: TurnActions,
    compProps: {
      handleAppointment,
      handleCancelAppointment
    },
    width: 20
  }];

  let headersWithoutActions = [
    { name: "Fecha desde:",
      accessor: "startDateF",
      type: "string"
    },
    { name: "Hora desde:",
      accessor: "startTimeF",
      type: "string"
    },
    { name: "Duración (minutos):",
      accessor: "durationF",
      type: "string",
      width: 20
    },
    { name: "Estado",
      accessor: "agendaTurnStateF",
      type: "string"
    },
    { name: "Paciente",
      accessor: "appointmentPatientNameF",
      type: "string"
    },
    { name: "Recurso",
      accessor: "resourceNameF",
      type: "string"
    }
  ];

  const headers = headersWithActions.concat(headersWithoutActions);

  const handleClose = () => {
    setAppointmentParams(null);
    props.history.replace({ ...props.history.location, state: undefined });
  }

  const handleLockTurns = () => {
    if(isLockCheckable){
      if(turnsSelected.length){
        const agendaTurnState = props.agendaTurnStates.find(turnState => turnState.key === "locked");
        props.updateAgendaTurnsState(turnsSelected.map(turn => turn._id), agendaTurnState._id).then((resp) => {
          setIsLockCheckable(false);
        });
      } else {
        setIsLockCheckable(false);
      }
    } else {
      setIsLockCheckable(true);
    }
  }

  const handleUnLockTurns = () => {
    if(isUnlockCheckable){
      if(turnsSelected.length){
        const agendaTurnState = props.agendaTurnStates.find(turnState => turnState.key === "free");
        props.updateAgendaTurnsState(turnsSelected.map(turn => turn._id), agendaTurnState._id).then((resp) => {
          setIsUnlockCheckable(false);
        });
      } else {
        setIsUnlockCheckable(false);
      }
    } else {
      setIsUnlockCheckable(true);
    }
  }

  const handlePrintTurns = () => {
    if(inTurns.length){
      const turnsWithJustDate = inTurns.map(turn => {
        turn.justDate = moment.utc(turn.startDatetime, "YYYY-MM-DD HH:mm").format("YYYY-MM-DD");
        return turn;
      });
      const turnDates = [];
      turnsWithJustDate.map(turn => {
        if(!turnDates.includes(turn.justDate)){
          turnDates.push(turn.justDate);
        }
        return turn;
      });
      const turnsToPrint = turnDates.map(date => {
        return {
          date: date,
          turns: turnsWithJustDate.filter(turn => turn.justDate === date)
        };
      })
      const utcOffset = moment.utc(inTurns[0].startDatetime, "YYYY-MM-DD HH:mm").local().utcOffset();
      props.generateTurnsPdf({
        turns: turnsToPrint,
        startDatetime: inTurns[0].startDatetime,
        endDatetime: inTurns[inTurns.length - 1].endDatetime,
        utcOffset
      }).then(resp => window.open(resp.value.gcUri))
    }
  }

  const handleAlertClose = () => setAlert({ open: false, title: "", content:"" });

  const handlePromptClose = () => setPromptData({ okAction: null, okParams: [], open: false, title: "", content: "" });

  return (
    <>
      <CustomAlert
        open={alert.open}
        title={alert.title}
        content={alert.content}
        handleClose={handleAlertClose}
      />
      <CustomPrompt
        okAction={promptData.okAction}
        okParams={promptData.okParams}
        open={promptData.open}
        title={promptData.title}
        content={promptData.content}
        handleClose={handlePromptClose}
      />
      <div className={classes.row}>
        {
          false && 
          <Grid item xs={12} className={classes.row}>
            <FilterTurnsByPatient />
          </Grid>
        }
        { (inTurns && inTurns.length > 0)  ?
          inTurns.map(((inTurn, i) => {
            return (
              <Appointment
                key={inTurn.id}
                appointment={inTurn}
                isFetching={props.fetching}
                handleCancelAppointment={handleCancelAppointment}
              />
            );
          })) :
          <div style={{ display: 'flex', alignItems: 'center', minHeight: "80vh" }}>
            { 
              props.fetching ?
                <div style={{ width: '100%', display: 'flex', flexDirection: "column", alignItems: "center", justifyContent: 'center' }}>
                  <CircularProgress />
                  <Typography style={{ marginTop: "20px" }}>Cargando información de turnos...</Typography>
                </div> : 
                <Chip
                  label="No hay resultados"
                  color="primary"
                />
            }
          </div>
        }
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    fetching: state.agendaTurns.isFetching || state.appointments.isFetching || state.appointmentStates.isFetching,
    agendaTurns: state.agendaTurns.items,
    appointmentStates: state.appointmentStates.items,
    appointments: state.appointments.items
  };
};

const mapDispatchToProps = (dispatch) => {

  const {
    fetchModelsIfNeeded: fetchAgendaTurnStatesIfNeeded
  } = agendaTurnStateActions.actions;

  const {
    fetchModelsIfNeeded: fetchAppointmentStatesIfNeeded
  } = appointmentStateActions.actions;

  const {
    updateModel: updateAppointment,
    getModelByQuery: getAppointmentsByQuery
  } = appointmentActions.actions;

  const {
    updateAgendaTurnsState,
    resetModel: resetTurns
  } = agendaTurnActions.actions;

  return bindActionCreators({
    fetchAgendaTurnStatesIfNeeded,
    fetchAppointmentStatesIfNeeded,
    getAppointmentsByQuery,
    resetTurns,
    updateAppointment,
    updateAgendaTurnsState,
    setSnack
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(TurnsList);
