import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';
import axios from "axios";
import Main from './components/Main';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';

import { setSnack } from './actions/globals';
import { BrowserRouter } from 'react-router-dom';


const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00652E'
    },
    secondary: {
      main: '#499F68'
    }
  },

});

function App(props) {

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <MuiThemeProvider theme={theme}>
          <BrowserRouter>
            <Main />
          </BrowserRouter>
        </MuiThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
