import {
  isMobile
} from "react-device-detect";

const drawerWidth = 210;
const drawerWidthResposive = 240;

const styles = (theme) => {
  return {
    mainRoot: {
      flexGrow: 1
    },
    navIcon: {
      marginRight: 8,
      '&:hover': {
        backgroundColor: '#3f51b5 !important',
      }
    },
    drawerPaper: {
      position: 'fixed',
      height: '100%',
      backgroundColor: '#fafafa',
      border: 0,
      width: 80
    },
    drawerPaperCollapse: {
      position: 'fixed',
      height: '100%',
      backgroundColor: '#fafafa',
      border: 0,
      width: 210
    },
    margin: {
      fontSize: isMobile ? 14 : 16
    },
    typography: {
      fontSize: isMobile ? 14 : 16
    },
    shareButton: {
      fontSize: isMobile ? 12 : 14
    },
    helpButton: {
      paddingLeft: 5
    },
    cardHeaderAction: {
      width: "25%",
      textAlign: "right"
    },
    root: {
      padding: isMobile ? theme.spacing(3) : theme.spacing(4),
      margin: 0
    },
    appBar: {
      position: 'fixed',
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: theme.palette.primary.main

    },
    toolBar: {
    	backgroundColor:  '#00652E', //'#01579B',
    	color:"white"
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    card: {
      marginBottom: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    },
    paper: {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0)
    },
    layoutPaper: {
      width: "100%"
    },
    cardHeader: {
      paddingLeft: isMobile ? theme.spacing(1) : theme.spacing(4),
      paddingRight: isMobile ? theme.spacing(1) : theme.spacing(4),
      paddingTop: isMobile ? theme.spacing(1) : theme.spacing(4),
      paddingBottom: isMobile ? theme.spacing(1) : theme.spacing(2),
    },
    cardTitle: {
      fontSize: isMobile ? 16 : 18
    },
    cardSubTitle: {
      fontSize: isMobile ? 13: 15
    },
    cell: {
      paddingBottom: theme.spacing(1),
    },
    buttonResult: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(1),
      display: "flex",
      justifyContent: "center"
    },
    linkHead: {
      textDecoration:"none",
      color: "white",
      '&:hover': {
        cursor: 'pointer',
      }
    },
    textFields: {
      paddingBottom: theme.spacing(2),
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(3),
      marginBottom: isMobile ? theme.spacing(1) : theme.spacing(6)
    },
    toolbar: theme.mixins.toolbar,
    content: {
      height: '100%',
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      minWidth: 0,
    },
    appFrame: {
      zIndex: 1,
      position: 'relative',
      display: 'flex'
    },
    contentTransition: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    contentNoTablet: {
      marginLeft: 60,
    },
    contentLeft: {
      marginLeft: -210,
    },
    contentShiftLeft: {
      marginLeft: -140,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  };
};

export default styles;
