import axios from 'axios';

export const SAVE_BRANCHOFFICE = 'SAVE_BRANCHOFFICE';
export const CURRENT_OFFICE = 'CURRENT_OFFICE';
export const ALL_OFFICE = 'ALL_OFFICE';

export const saveBranchOffices = (branchOffices, defaultBranchOffice) => {
  return {
    type: SAVE_BRANCHOFFICE,
    payload: { branchOffices, defaultBranchOffice: defaultBranchOffice || 'allBranchOffice' },
  };
};

export const currentBranchOffice = (currentID) => {
  return {
    type: CURRENT_OFFICE,
    payload: { currentID }
  };
};

export const allBranchOffice = () => {
  return {
    type: ALL_OFFICE,
    payload: axios.get(`${process.env.REACT_APP_MEDEXWARE_RIS_URL}/api/branchOffice/allmethods`)
      .then((response) => {
        return {
          branchOffices: response.data,
        };
      })
  };
};

const shouldFetchBranchOffices = (state) => {
  const { branchOffices } = state;
  if (!branchOffices.allBranchOffices.length) {
    return true;
  }
  if (branchOffices.isFetching) {
    return false;
  }
  return branchOffices.didInvalidate;
};

export const fetchBranchOfficesIfNeeded = () => {
  return (dispatch, getState) => {
    if (shouldFetchBranchOffices(getState())) {
      return dispatch(allBranchOffice());
    }
  };
};