import genericActions from "./generic.actions";

const actions = genericActions({
  modelToAction: "AGENDA_TYPE",
  modelToRoute: "agendaType",
  modelToState: "agendaTypes",
  axiosBaseURL: process.env.REACT_APP_MEDEXWARE_APPOINTMENT_URL,
  authToken: "appointmentToken"
});

export default actions;