import React, { useEffect, useState } from 'react';
import { useTable, usePagination } from 'react-table';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import CircularProgress from '@material-ui/core/CircularProgress';

import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';

import ClearIcon from  '@material-ui/icons/Clear';
import CheckIcon from  '@material-ui/icons/Check';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0].original, b[0].original);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {

  const { classes,
          onSelectAllClick,
          order,
          orderBy,
          numSelected,
          rowCount,
          onRequestSort,
          headerGroups } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      { headerGroups.map(headerGroup => (
        <TableRow {...headerGroup.getHeaderGroupProps()}>
          { props.collapsable && <TableCell></TableCell> }
          { props.isCheckable &&
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{ 'aria-label': 'selecte all items' }}
              />
            </TableCell>
          }
          { headerGroup.headers.map(column => {
              return(
              <TableCell
                key={column.id}
                align={column.numeric ? 'right' : 'left'}
                padding={column.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === column.id ? order : false}
                {...column.getHeaderProps()}
              >
                <TableSortLabel
                  active={orderBy === column.id}
                  direction={orderBy === column.id ? order : 'asc'}
                  onClick={createSortHandler(column.id)}
                >
                  { column.render('Header') }
                  { orderBy === column.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                    ) : null
                  }
                </TableSortLabel>
              </TableCell>
            )}
          )}
        </TableRow>
      ))}
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, title } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      { numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} seleccionada
        </Typography>
        ) : (
          <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
            { props.title }
          </Typography>
        )
      }
      {
        numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton aria-label="filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )
      }
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  title: PropTypes.string.isRequired,
  numSelected: PropTypes.number.isRequired
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function EnhancedTable(props) {
  const classes = useStyles();
  const [ order, setOrder] = React.useState('asc');
  const [ orderBy, setOrderBy] = React.useState('name');
  const [ selected, setSelected] = React.useState([]);
  const [ collapseOpen, setCollapseOpen ] = useState([]);
  const { headers, dataRows, title, dense, handleSelection, alreadySelected, CollapsableComponent } = props;

  useEffect(() => {
    if(props.alreadySelected && props.alreadySelected.length){
      const alSel = dataRows.filter(item => alreadySelected.includes(item._id)).map(kitem => ({ item: kitem, included: true }));
      handleSelection(alSel);
      setSelected(alSel);
    }
  }, [props.alreadySelected, dataRows]);

  let columnsArray = [];

  props.headers.map((column) => {
    if(column.type === "component"){
      columnsArray.push({
        Header: column.name,
        width: column.width,
        Cell: (row) => {
          let compProps = {};
          if (column.compProps){
            compProps = {...column.compProps}
          }
          return (
            <React.Fragment>
                <column.Component row={row.row.original} { ...compProps } form={column.formRequire ? `${column.formPrefix}-${row.row.original.id}` : null} />
            </React.Fragment>
          );
        }
      });
    } else {
      if(column.type === "boolean"){
        columnsArray.push({
          Header: column.name,
          accessor: column.accessor,
          Cell: (row) => {
            return (
              <React.Fragment>
                { row.row.values[column.accessor] ? <CheckIcon /> : <ClearIcon /> }
              </React.Fragment>
            );
          }
        });
      } else {
        columnsArray.push({
          Header: column.name,
          accessor: column.accessor,
          width: column.width
        });
      }
    }
  });

  const columns = React.useMemo(() => columnsArray, [dataRows])

  const data = React.useMemo(() => dataRows, [dataRows])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    pageOptions,
    page,
    state: { pageIndex, pageSize },
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage
  } = useTable({
    columns,
    data
  }, usePagination)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => {
        const included = alreadySelected.find(s => s === n.original._id) ? true : false;
        return { item: n.original, included: included };
      }).filter(n => n);
      handleSelection(newSelecteds);
      setSelected(newSelecteds);
      return;
    }
    const newS = selected.filter(sel => sel.included);
    handleSelection(newS);
    setSelected(newS);
  };

  const handleClick = (event, item) => {
    const selectedMaped = selected.map(s => s.item._id);
    const selectedIndex = selectedMaped.indexOf(item._id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, { item: item, included: false });
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    handleSelection(newSelected);
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    gotoPage(newPage)
  }

  const handleOpenCollapse = (rowId) => {
    if(!collapseOpen.includes(rowId)){
      setCollapseOpen(collapseOpen.concat(rowId));
    } else {
      const aux = collapseOpen.filter(open => open !== rowId);
      setCollapseOpen(aux);
    }
  }

  const pageSizeOptions = [5,10,20,30,50];

  const isSelected = (accessor) => selected.map(s => s.item._id).indexOf(accessor) !== -1;

  const emptyRows = pageSize - Math.min(pageSize, rows.length - page * pageSize);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        { props.enableToolbar &&
          <EnhancedTableToolbar
            numSelected={selected.length}
            title={title}
          />
        }
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? (rows.length === 0 ? 'medium' : "small") : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headerGroups={headerGroups}
              collapsable={props.collapsable}
              isCheckable={props.isCheckable}
            />
            <TableBody>
              { stableSort(page, getComparator(order, orderBy))
                .map((row, index) => {
                  const isItemSelected = isSelected(row.original._id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  prepareRow(row)
                  const sel = selected.find(s => s.item._id === row.original._id);
                  return (
                    <>
                      <TableRow
                        {...row.getRowProps()}
                        onClick={(event) => {
                          if(props.isCheckable){
                            const sel = selected.find(s => s.item._id === row.original._id)
                            if(sel && sel.included) {
                              return;
                            } else {
                              return handleClick(event, row.original);
                            }
                          }
                        }}
                        role={props.isCheckable ? "checkbox" : "row"}
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.accessor}
                        selected={isItemSelected}
                      >
                      { props.collapsable &&
                        <TableCell>
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={(event) => {
                              event.stopPropagation();
                              return handleOpenCollapse(row.original._id)
                            }}
                          >
                            { collapseOpen.includes(row.original._id) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                          </IconButton>
                        </TableCell>
                      }
                      { props.isCheckable &&
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                            disabled={sel && sel.included}
                          />
                        </TableCell>
                      }
                      {row.cells.map(cell => {
                        return (
                          <TableCell width={cell.column.width} {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </TableCell>
                        )
                      })}
                      </TableRow>
                    { props.collapsable &&
                      <TableRow
                        selected={isItemSelected}
                      >
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={props.headers.length + 2}>
                          <Collapse in={collapseOpen.includes(row.original._id)} timeout="auto" unmountOnExit>
                            { CollapsableComponent && <CollapsableComponent row={row.original}/> }
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    }
                    </>
                  )
                })}
              { rows.length === 0 &&
                (
                  props.isFetching ?
                  <TableRow>
                    <TableCell colSpan={props.headers.length + 2 } >
                      <Grid container>
                        <Grid item xs={12} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                          <CircularProgress size={18}/><Typography style={{ marginLeft: "20px" }} color="primary">Cargando...</Typography>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow> :
                  <TableRow>
                    <TableCell colSpan={props.headers.length + 2 } >
                      <Grid container>
                        <Grid item xs={12} align="center">
                          { "Sin registros que mostrar" }
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          style={{ paddingRight: "65%"}}
          colSpan={headerGroups.length - 1}
          rowsPerPageOptions={pageSizeOptions}
          component="div"
          count={rows.length}
          rowsPerPage={pageSize}
          page={pageIndex}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={e => {
            setPageSize(Number(e.target.value))
          }}
          labelRowsPerPage={"Registros a mostrar: "}
        />
      </Paper>
    </div>
  );
}
