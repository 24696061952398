import React, { useState } from "react";
import axios from "axios";
import { Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core";
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import ListIcon from '@material-ui/icons/LibraryBooks';
import CalendarViewDayIcon from '@material-ui/icons/CalendarViewDay';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import AdmissionsList from "./admissions/AdmissionsList";
import TurnsList from "./turns/TurnsList";

import SuspenseFallback from "../commons/SuspenseFallback";
import CustomSnackBar from "../commons/CustomSnackBar"
import { setSnack } from "../../actions/globals";
import { isMobile } from "react-device-detect";


const useStyles = makeStyles((theme) => ({
    bottomBar: {
      height: "90px",
      backgroundColor: "#499F68"
    },
    bottomNavLabel: {
      marginTop: "10px"
    },
    bottomSelected: {
      color: "white"
    },
    mainContainer: {
        zIndex: 1,
        position: 'relative',
        display: 'flex',
        justifyContent: "space-between",
        flexDirection: "column",
        height: `calc(100vh - ${isMobile ? "56px" : "65px"})`
    },
    routesContainer: {
        paddingTop: "20px"
    }
}));

const Dashboard = (props) => {

    const classes = useStyles();
    
    const [ bottomValue, setBottomValue ] = useState("admissions");

    const snack = useSelector(state => state.globals.snack);
    const dispatch = useDispatch();
    
    const handleSnakClose = (state) => {
        dispatch(setSnack({
            severity: snack.severity,
            open: state,
            message: snack.message
        }))
    }

    return(
        <React.Fragment>
            <CustomSnackBar 
                severity={ snack.severity }
                open={ snack.open }
                message={ snack.message }
                setExternalOpen={ handleSnakClose }
            />
            <div className={classes.mainContainer}>
                <div className={classes.routesContainer}>
                    <Route exact path="/" component={AdmissionsList} />
                    <React.Suspense fallback={SuspenseFallback}>
                        <Route exact path="/admissions" component={AdmissionsList} />
                        <Route exact path="/turns" component={TurnsList} />
                    </React.Suspense>
                </div>
                {
                    isMobile &&
                    <div>
                        <BottomNavigation
                            value={bottomValue}
                            onChange={(event, newValue) => {
                                setBottomValue(newValue);
                                if(newValue){
                                props.history.push({ pathname: `/${newValue}` });
                                }
                            }}
                            showLabels
                            className={classes.bottomBar}
                        >
                            <BottomNavigationAction value="admissions" classes={{ label: classes.bottomNavLabel, selected: classes.bottomSelected }} label="Admisiones" icon={<ListIcon style={{ color: bottomValue === "admissions" ? "white" : null }} />} />
                            <BottomNavigationAction value="turns" classes={{ label: classes.bottomNavLabel, selected: classes.bottomSelected  }} label="Turnos" icon={<CalendarViewDayIcon style={{ color: bottomValue === "turns" ? "white" : null }} />} />
                        </BottomNavigation>
                    </div>
                }
            </div>
        </React.Fragment>
    );
}

export default Dashboard;