import axios from "axios";

import genericActions from "./generic.actions";

const CHANGE_PASSWORD = "CHANGE_PASSWORD";
const CHANGE_CONTACT_INFO = "CHANGE_CONTACT_INFO";

const changePassword = (user) => {
  return {
    type: CHANGE_PASSWORD,
    payload: axios.put(`${process.env.REACT_APP_MEDEXWARE_RIS_URL}/api/user/changePassword`, user)
    .then((json) => {
      return {
        user: json.data
      };
    })
  };
};

const changeContactInfo = (user) => {
  return {
    type: CHANGE_CONTACT_INFO,
    payload: axios.put(`${process.env.REACT_APP_MEDEXWARE_RIS_URL}/api/user/changeContactInfo`, user)
    .then((json) => {
      return {
        user: json.data.user
      };
    })
  };
};

const actions = genericActions({
  modelToAction: "USER",
  modelToRoute: "usuario",
  modelToState: "users"
});

actions.actionTypes["CHANGE_PASSWORD"] = CHANGE_PASSWORD;
actions.actions["changePassword"] = changePassword;

actions.actionTypes["CHANGE_CONTACT_INFO"] = CHANGE_CONTACT_INFO;
actions.actions["changeContactInfo"] = changeContactInfo;

export default actions;
