import axios from 'axios';
export const GET_ADMISSIONS = 'GET_ADMISSIONS';
export const RESET_ADMISSIONS = 'RESET_ADMISSIONS';
export const GENERATE_ADMISSION_PDF = "GENERATE_ADMISSION_PDF";
export const VERIFY_STUDY_INSTANCE_UIDS = "VERIFY_STUDY_INSTANCE_UIDS";


export const getAdmissionsFilter = (query, skip, limit) => {
    const newQuery = `${query}&populate=1`;
    return {
        type: GET_ADMISSIONS,
        payload: axios
        .get(`${process.env.REACT_APP_MEDEXWARE_RIS_URL}/api/admission/find/${query}${skip ? `&skip=${skip}` : ''}${
            limit ? `&limit=${limit}` : ''
        }`)
        .then((resp) => {
            const { response, count } = resp.data;
            return {
                admissions: response,
                concat: !!skip,
                lastQuery: query,
                count
            };
        })
    };
};

export const verifyStudyInstanceUID = ({ admissionId, studyInstanceUids }) => {
    return {
      type: VERIFY_STUDY_INSTANCE_UIDS,
      payload: axios.get(`${process.env.REACT_APP_MEDEXWARE_RIS_URL}/api/admission/pacsapi/${admissionId}/${studyInstanceUids}`).then((resp) => {
        return {
          data: resp.data
        };
      }).catch(error => {
        return {
          data: error
        };
      })
    };
  }
  
  export const generateAdmissionPDF = (id) => {
    return {
      type: GENERATE_ADMISSION_PDF,
      payload: axios.get(`${process.env.REACT_APP_MEDEXWARE_RIS_URL}/api/report/pdf/${id}/true`).then((resp) => {
        return {
          file: resp.data.gcUri
        };
      })
    };
  }

export const resetAdmissions = () => {
    return {
      type: RESET_ADMISSIONS
    };
};