import { 
    GET_PROFESSIONALS,
    GET_PROFESSIONAL_BY_ID 
} from '../actions/professional';

const INITIAL_STATE = {
isFetching: false,
didInvalidate: false,
items: [],
itemId: {},
};

const professionals = (state = INITIAL_STATE, action) => {

    switch (action.type) {

        case `${GET_PROFESSIONALS}_REJECTED`:{
            return {
                ...state,
                isFetching: false,
                didInvalidate: true
            };
        }
        case `${GET_PROFESSIONALS}_PENDING`:{
            return {
                ...state,
                isFetching: true,
                didInvalidate: false
            };
        }

        case `${GET_PROFESSIONALS}_FULFILLED`:{
            return {
                ...state,
                isFetching: false,
                didInvalidate: false,
                items: action.payload.professionals
            };
        }

        case `${GET_PROFESSIONAL_BY_ID}_REJECTED`:{
            return {
                ...state,
                isFetching: false,
                didInvalidate: true
            };
        }

        case `${GET_PROFESSIONAL_BY_ID}_PENDING`:{
            return {
            ...state,
            isFetching: true,
            didInvalidate: false
            };
        }

        case `${GET_PROFESSIONAL_BY_ID}_FULFILLED`: {
            return {
                ...state,
                isFetching: false,
                didInvalidate: false,
                itemId: action.payload.professional
            };
        }

        default:{
            return state;
        }

    }
};

export default professionals;
