import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import { 
    makeStyles, 
    Grid,
    Typography,
    TextField,
    Button,
    CircularProgress,
    InputAdornment,
    IconButton
} from "@material-ui/core";

import {
    Pageview,
    ExitToApp,
    Visibility as VisibilityIcon
} from "@material-ui/icons";

import { Link } from "react-router-dom";

import { isMobile } from "react-device-detect";

import { signIn } from "../actions/auth";
import { setSnack } from "../actions/globals";

const useStyles = makeStyles((theme) => ({
    root: {
        flex: true,
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(2),
        minHeight: "82vh"
    },
    signinContainer: {
        marginTop: theme.spacing(3)
    },
    link: {
        textDecoration: "none",
        fontWeight: "600",
        color: "black"
    },
    links: {
        textAlign: "center"
    },
    extendedIcon: {
        marginRight: theme.spacing(2)
    }
}));

const SigninLayout = (props) => {

    const classes = useStyles();

    const dispatch = useDispatch();

    const fetching = useSelector(state => state.auth.isFetching);

	const [ usernameRequired, setUsernameRequired ] = useState(false);
	const [ passwordRequired, setPasswordRequired ] = useState(false);
	const [ errorMessage, setErrorMessage ] = useState("");
	const [ message, setMessage ] = useState("");
    const [ passwordType, setPasswordType ] = useState("password");

    const [ touched, setTouched ] = useState(false);

	const inputUsername = useRef(null);
	const inputPassword = useRef(null);

    useEffect(() => {
        if(props.location.state && props.location.state.from){
            const { from, message: messageState, error: errorState } = props.location.state;
            if(from === "signup"){
                setMessage(messageState);
            }
            if(from === "confirmEmail"){
                setMessage(messageState);
            }
            if(from === "forgot"){
                if(errorState){
                    setErrorMessage(errorState);
                } else {
                    setMessage(messageState);
                }
            }
            if(from === "reset"){
                setMessage(messageState);
            }
        }
    }, []);

    const handleSignIn = async (values) => {
        try {

            const { username, password } = values;

            if(!username || !password){
                setTouched(true);
                if(!username){
                    setUsernameRequired(true);
                }
                if(!password){
                    setPasswordRequired(true);
                }
            } else {
                setErrorMessage("");
                const resp = await dispatch(signIn(values));
                axios.defaults.headers.common = {
                    Authorization: `Bearer ${resp.value.data.jwt}`
                };
                axios.interceptors.response.use((response) => {
                    return response;
                }, (error) => {
                    if(error.response && error.response.status === 401) {
                        props.history.push("/signin");
                        dispatch(setSnack({ open: true, severity: "error", message: "Error de acceso, por favor incie sesión nuevamente" }));
                    }
                    if(error.response && error.response.status === 500) {
                        console.log("Error message: ", error.response.data.message)
                    }
                    if(error.response && error.response.status === 404) {
                        dispatch(setSnack({ open: true, severity: "error", message: "Elemento no encontrado" }));
                    }
                });
                props.history.push('/');
            }
        } catch (error) {
            if(error.response){
                if(error.response.status === 401){
                    setErrorMessage("Usuario no autorizado, por favor verifique usuario y contraseña")
                } else {
                    setErrorMessage("Ocurrió un error a nivel del sistema, por favor contacte al administrador")
                }
            }
            console.log("Catch error: ", error);
        }

    }

    const handleChange = (value, field) => {
        setErrorMessage("");
        if(!value){
            if(field === "username"){
                setUsernameRequired(true);
            } else {
                setPasswordRequired(true);
            }
        } else {
            if(field === "username"){
                setUsernameRequired(false);
            } else {
                setPasswordRequired(false);
            }
        }
    }

    return (
        <Grid container className={classes.root}>
            <Grid item xs={10} sm={6} md={6} lg={3} className={classes.signinContainer}>
                <Grid container spacing={3}>
                    <Grid item xs={12} align="center">
                        <img width={isMobile? 128 : 192 } height={isMobile ? 37 : 56 }  src={`${process.env.REACT_APP_STATIC_IMAGES_URL}/logoCliente.png`} />
                    </Grid>
                    <Grid item xs={12} align="center">
                        <Typography variant="h6" style={{ fontWeight: 'bold'}}>
                            Iniciar sesión
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField 
                            fullWidth
                            error={touched && usernameRequired}
                            inputRef={inputUsername}
                            autoFocus={true}
                            id={"username"}
                            label="Usuario"
                            variant="outlined"
                            helperText={ (touched && usernameRequired) ? "El campo es requerido" : "" } 
                            onChange={(event) => handleChange(event.target.value, "username")}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField 
                            fullWidth
                            type={passwordType}
                            error={touched && passwordRequired}
                            inputRef={inputPassword}
                            id={"password"}
                            label="Contraseña"
                            variant="outlined"
                            helperText={ (touched && passwordRequired) ? "El campo es requerido" : "" } 
                            onChange={(event) => handleChange(event.target.value, "password")}
                            InputProps={{
                                endAdornment: 
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => {
                                                let type;
                                                if(passwordType === "password"){
                                                    type = "text";
                                                } else {
                                                    type = "password";
                                                }
                                                setPasswordType(type);
                                            }}
                                        >
                                            <VisibilityIcon/>
                                        </IconButton>
                                    </InputAdornment>
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            fullWidth
                            variant="contained"
                            size="large"
                            color="primary"
                            aria-label="add"
                            onClick={() => handleSignIn({ username: inputUsername.current.value, password: inputPassword.current.value })}
                            disabled={fetching}
                        >
                            { fetching ? <CircularProgress size={24} className={classes.extendedIcon} />:<ExitToApp className={classes.extendedIcon} /> }
                            Ingresar
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography style={{ color: errorMessage ? "red" : "green" }}>{ errorMessage || message }</Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.links}>
                        <Link className={classes.link} to="/signup"><Typography>Registrarme</Typography></Link>
                    </Grid>
                    <Grid item xs={12} className={classes.links}>
                        <Link className={classes.link} to="/forgotten"><Typography>Olvidé mi contraseña</Typography></Link>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default SigninLayout