import React from "react";

import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

const SuspenseFallback = (props) => {
  return(
    <Grid container
          direction="row"
          justify="center"
          alignItems="center"
          style={{ width: "80vw", height: "80vh" }}
    >
      <Grid item xs={12} align="center">
        <CircularProgress />
        <Typography>Cargando, por favor espere ...</Typography>
      </Grid>
    </Grid>
  );
}

export default SuspenseFallback;
