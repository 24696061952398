import React from "react";

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import CustomDialog from "./CustomDialog";

const CustomAlert = (props) => {
  const { open, title, content, handleClose } = props;

  const dialogTitle =
  <Typography variant="h5">
    { title }
  </Typography>

  const dialogContent =
    <Grid container>
      <Grid item xs={12} style={{ marginTop: 11 }}>
        <Typography>{ content }</Typography>
      </Grid>
    </Grid>

  const actions =
    <React.Fragment>
        <Button
          variant="outlined"
          style={{ marginLeft: 15, width: 150 }}
          color="secondary"
          onClick={ () => handleClose() }
        >
          Ok
        </Button>
    </React.Fragment>;

  return (
    <CustomDialog
      handleClose={handleClose}
      handleCloseParams={[]}
      open={open}
      title={dialogTitle}
      actions={actions}
      content={dialogContent}
      maxWidth="sm"
      fullWidth
      dividers={["top","bottom"]}
    />
  );
}

export default CustomAlert;
