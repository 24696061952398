import React, { useEffect, useState } from 'react';
import axios from "axios";
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Button from "@material-ui/core/Button";
import { useTheme } from '@material-ui/core/styles';

import { isMobile } from "react-device-detect";
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import blue from '@material-ui/core/colors/blue';
import styles from '../styles/global';
import config from '../config/config';

import Layout from './Layout';
import StudyLayout from './StudyLayout';
import PrivateRoute from "./auth/PrivateRoute";
import Menu from "./commons/Menu";
import UserLayout from "./commons/UserLayout";
import ChangePasswordDialog from './commons/ChangePasswordDialog';
import ChangeContactInfoDialog from './commons/ChangeContactInfoDialog';
import ConfirmEmail from "./auth/ConfirmEmail";

import Dashboard from "./dashboard/Dashboard";

import { deleteToken, resetAuthFetching } from '../actions/auth';
import { setSnack } from "../actions/globals";

import history from './history.js';
import { useLocation } from 'react-router-dom';

import {
  useParams,
  Link,
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";
import { Store } from '@material-ui/icons';
import SigninLayout from './SiginLayout';
import SignupLayout from './SignupLayout';
import ForgottenLayout from "./ForgottenLayout";
import ResetPassLayout from "./auth/ResetPassLayout";

// const theme = createMuiTheme({
// 	palette: {
// 	    primary: blue
// 	}
// });

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#6EAA6F',
      main: '#499F68',
      dark: '#00652E',
      contrastText: '#FFFFFF',
    },
    secondary: {
      light: '#EDEDED',
      main: '#D9D9D9',
      dark: '#00000034',
      contrastText: '#FFFFFF',
    }
  }
});


const drawerWidth = 210;

function Main(props) {

  const auth = useSelector(state => state.auth);
	const token = useSelector(state => state.auth.risToken);

  const location = useLocation();
  
  const [ openDrawer, setOpenDrawer ] = useState(false);
  const [ transition, setTransition ] = useState(false);
  const [ modalPasswordOpen, setModalPasswordOpen ] = useState(false);
  const [ modalContactOpen, setModalContactOpen ] = useState(false);
  const [ pathname, setPathname ] = useState("/");

	const { classes } = props;
  
  const theme = useTheme();
  
  axios.defaults.baseURL = process.env.REACT_APP_MEDEXWARE_RIS_URL;
  
  if(token){
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`
    };
    axios.interceptors.response.use((response) => {
        return response;
    }, (error) => {
        if(error.response && error.response.status === 401) {
            history.push("/signin");
            dispatch(setSnack({ open: true, severity: "error", message: "Error de acceso, por favor incie sesión nuevamente" }));
        }
        if(error.response && error.response.status === 500) {
            console.log("Error message: ", error.response.data.message)
        }
        if(error.response && error.response.status === 404) {
            dispatch(setSnack({ open: true, severity: "error", message: "Elemento no encontrado" }));
        }
    });
  }

  const dispatch = useDispatch();

  const eventChange = () => {
    if (document.documentElement.clientWidth < theme.breakpoints.values.md) {
      setOpenDrawer(false);
      setTransition(false);
    } else if (document.documentElement.clientWidth > theme.breakpoints.values.md && !openDrawer) {
      setOpenDrawer(false);
      setTimeout(() => { setTransition(true); }, 100);
    }
  };

  const changeStateDevice = () => {
    window.addEventListener('resize', eventChange);

    if (window.screen.width < theme.breakpoints.values.lg) {
      setOpenDrawer(false);
    }
  }

  useEffect(() => {
    changeStateDevice();
    axios.defaults.baseURL = process.env.REACT_APP_MEDEXWARE_RIS_URL;
    dispatch(resetAuthFetching());
  }, []);

  useEffect(() => {
    if(token){
      axios.defaults.baseURL = process.env.REACT_APP_MEDEXWARE_RIS_URL;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${token}`
      };
      axios.interceptors.response.use((response) => {
          return response;
      }, (error) => {
          if(error.response && error.response.status === 401) {
            dispatch(deleteToken());
            dispatch(setSnack({ open: true, severity: "error", message: "Error de acceso, por favor incie sesión nuevamente" }));
            history.push("/signin");
            localStorage.removeItem('persist:root');
            window.location.reload();
          }
          if(error.response && error.response.status === 500) {
              console.log("Error message: ", error.response.data.message)
          }
          if(error.response && error.response.status === 404) {
              dispatch(setSnack({ open: true, severity: "error", message: "Elemento no encontrado" }));
          }
      });
    }
  }, [token])

  const deleteTokenFunc = () => {
    setOpenDrawer(false);
    dispatch(deleteToken());
    history.push("/signin");
    localStorage.removeItem('persist:root');
    window.location.reload();
  }

  const handleDrawerToggle = () => {
    setOpenDrawer(!openDrawer)
  }

  const handleModalClose = () => {
    setModalPasswordOpen(!modalPasswordOpen);
  };

  const handleModalContactClose = () => {
    setModalContactOpen(!modalContactOpen);
  }

  const drawer = (
    <div style={{ height: '100%', overflow: "hidden" }}>
      <div className={classes.toolbar} />
      <Switch>
        <Route 
          path="/" 
          render={(props) => { 
            return (
              <Menu 
                {...props} 
                deleteTokenFunc={deleteTokenFunc}  
                auth={auth} 
              />
            ); 
          }} 
        />
      </Switch>
    </div>
  );

  const handleGoTo = () => {
    if(location.pathname === "/"){
      setPathname("/signin");
      history.push({ pathname: "/signin" });
      localStorage.removeItem('persist:root');
      window.location.reload();
    } else {
      setPathname("/");
      history.push({ pathname: "/" });
      localStorage.removeItem('persist:root');
      window.location.reload();
    }
  }

  console.log(location);

	return (
    <React.Fragment>
      <div>
        <ChangePasswordDialog
          open={modalPasswordOpen}
          handleModalClose={handleModalClose}
          userName={auth.userName}
        />
        <ChangeContactInfoDialog
          open={modalContactOpen}
          handleModalClose={handleModalContactClose}
        />
      </div>
      <Router history={history}>
        <Switch>
          <div className={classes.mainRoot}>
            <div className={classes.appFrame}>
              <AppBar className={classes.appBar}>
                <Toolbar className={ classes.toolBar }>
                  {
                    (auth.risToken && !isMobile) &&
                    <IconButton
                      disableRipple
                      color="inherit"
                      aria-label="Open drawer"
                      onClick={handleDrawerToggle}
                      className={classes.navIcon}
                    >
                      <MenuIcon style={{ fontSize: 30 }} />
                    </IconButton>
                  }
                  <Typography variant="h6"><Link className={classes.linkHead} to={ "/" }>Portal del Paciente</Link></Typography>
                  <div
                      style={{
                        display: 'flex',
                        flex: 1,
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        width: "20%"
                      }}
                    >
                      { auth.risToken ? 
                        <UserLayout
                          userName={auth.userName}
                          history={history}
                          deleteToken={deleteTokenFunc}
                          handleModalClose={handleModalClose}
                          handleModalContactClose={handleModalContactClose}
                        /> :
                        <Typography variant="body1" style={{ marginRight: "20px", fontWeight: "500" }}>
                          { location.pathname === "/" || location.pathname.split("/")[1] === "study" || location.pathname.split("/")[1] === "admission" ?
                            <Button variant="link" className={classes.linkHead} onClick={handleGoTo}>
                              INGRESAR
                            </Button> : 
                            <Button variant="link" className={classes.linkHead} onClick={handleGoTo}>
                              CONSULTAR
                            </Button>
                          }
                        </Typography>
                      }
                  </div>
                </Toolbar>
              </AppBar>
              <Drawer
                variant={ isMobile ? null : 'persistent' }
                open={openDrawer}
                onClose={handleDrawerToggle}
                classes={{
                  paper: isMobile ? classes.drawerPaperCollapse : classes.drawerPaper,
                }}
                style={{ overflow: "hidden" }}
              >
                {drawer}
              </Drawer>
              { isMobile ? null : <div style={{ marginLeft: drawerWidth }} />}
              <main
                className={classNames(classes.content, {
                  [classes.contentNoTablet]: !isMobile,
                  [classes.contentLeft]: !isMobile,
                  [classes.contentTransition]: transition,
                  [classes.contentShift]: openDrawer && !isMobile,
                  [classes.contentShiftLeft]: openDrawer && !isMobile,
                })}
              >
                <div className={classes.toolbar} />
                  <PrivateRoute
                    exact
                    path="/signin"
                    component={Dashboard}
                    publicComponent={SigninLayout}
                    history={history}
                    {...props}
                    auth={auth}
                    deleteToken={deleteToken}
                  />
                  <PrivateRoute
                    exact
                    path="/signup"
                    component={Dashboard}
                    publicComponent={SignupLayout}
                    history={history}
                    {...props}
                    auth={auth}
                    deleteToken={deleteToken}
                  />
                  <PrivateRoute
                    exact
                    path="/forgotten"
                    component={Dashboard}
                    publicComponent={ForgottenLayout}
                    history={history}
                    {...props}
                    auth={auth}
                    deleteToken={deleteToken}
                  />
                  <PrivateRoute
                    exact
                    path="/passreset/:token"
                    component={Dashboard}
                    publicComponent={ResetPassLayout}
                    history={history}
                    {...props}
                    auth={auth}
                    deleteToken={deleteToken}
                  />
                  <PrivateRoute
                    exact
                    path="/getbydocandadmission/:document/:admission"
                    component={Dashboard}
                    publicComponent={Layout}
                    history={history}
                    {...props}
                    auth={auth}
                    deleteToken={deleteToken}
                  />
                  <PrivateRoute
                    exact
                    path="/admission/:id"
                    component={Dashboard}
                    publicComponent={Layout}
                    history={history}
                    {...props}
                    auth={auth}
                    deleteToken={deleteToken}
                  />
                  <PrivateRoute
                    exact
                    path="/study/:id"
                    component={Dashboard}
                    publicComponent={StudyLayout}
                    history={history}
                    {...props}
                    auth={auth}
                    deleteToken={deleteToken}
                  />
                  <PrivateRoute
                    exact
                    path="/confirmEmail/:token"
                    component={Dashboard}
                    publicComponent={ConfirmEmail}
                    history={history}
                    {...props}
                    auth={auth}
                    deleteToken={deleteToken}
                  />
                  <PrivateRoute
                    exact={!auth.risToken}
                    path="/"
                    component={Dashboard}
                    publicComponent={Layout}
                    history={history}
                    {...props}
                    auth={auth}
                    deleteToken={deleteToken}
                  />
              </main>
            </div>
          </div>
        </Switch>
      </Router>
    </React.Fragment>
	);

}

export default withStyles(styles, { withTheme: true })(Main);
