import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, topDivider, disableCloseButton, ...other } = props;
  return (
    <React.Fragment>
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        {children}
        {(onClose && !disableCloseButton) ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
      { topDivider ? <Divider/> : null }
    </React.Fragment>
  );
});

const DialogContent = withStyles((theme) => ({
    root: {
      margin: theme.spacing(1),
      padding: theme.spacing(2)
    },
    noMargin: {
      marginTop: 0,
      padding: theme.spacing(1)
    },
  }))((props) => {
  const { children, classes, noMargin, ...other } = props;
  return (
    <MuiDialogContent className={noMargin ? classes.noMargin : classes.root } {...other}>
      {children}
    </MuiDialogContent>
  );
});

const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))((props) => {
  const { children, classes, bottomDivider, ...other } = props;
  return (
    <React.Fragment>
      { bottomDivider ? <Divider /> : null }
      <MuiDialogActions className={classes.root} {...other}>
        {children}
      </MuiDialogActions>
    </React.Fragment>
  );
});

const CustomDialog = (props) => {

  const { open,
          handleClose,
          handleCloseParams,
          title,
          actions,
          content,
          maxWidth,
          fullWidth,
          dividers,
          disableBackdropClick,
          disableCloseButton } = props;

  const topDivider = dividers && dividers.includes("top");
  const bottomDivider = dividers && dividers.includes("bottom");

  return (
    <Dialog onClose={() => handleClose(...handleCloseParams)}
            aria-labelledby={`customized-dialog-title-${props.key}`}
            open={open}
            maxWidth={maxWidth}
            fullWidth={fullWidth}
            disableBackdropClick={disableBackdropClick}
    >
      <DialogTitle id={`customized-dialog-title-${props.key}`}
                   onClose={() => handleClose(...handleCloseParams)}
                   topDivider={topDivider}
                   disableCloseButton={disableCloseButton}
      >
        { title }
      </DialogTitle>
      <DialogContent noMargin={props.noMargin}>
        { content }
      </DialogContent>
      <DialogActions bottomDivider={bottomDivider}>
        { actions }
      </DialogActions>
    </Dialog>
  );
}

export default CustomDialog;
