import React, { Component, useEffect, useState } from 'react';
import { Field, FormikProvider, useFormik } from "formik";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FilterList from '@material-ui/icons/FilterList';
import MenuItem from '@material-ui/core/MenuItem';
import ClearAll from '@material-ui/icons/Clear';

import { getAdmissionsFilter, resetAdmissions } from '../../../actions/admission';
import { fetchProfessionalsIfNeeded, getProfessionalById } from '../../../actions/professional';
import { fetchModalitysIfNeeded } from '../../../actions/modality';
import { fetchServicesIfNeeded } from '../../../actions/service';
import { getSolicitesByClient } from '../../../actions/solicite';
import { getFunderByUserId } from '../../../actions/funder';

import CustomTextField from '../../commons/form/CustomTextField';
import CustomSelect from '../../commons/form/CustomSelect';
import CustomAutocomplete from '../../commons/form/CustomAutocomplete';

const styles = (theme) => {
  return {
    root: {
      ...theme.mixins.gutters(),
      paddingBottom: theme.spacing(2),
      paddingTop: theme.spacing(2),
      marginBottom: theme.spacing(4),
      background: '#f1f1f1',
      border: 'none'
    },
    marginTop: {
      marginTop: theme.spacing(1),
    },
    color: {
      color: 'red',
      fontSize: 50
    },
    cssLabel: {
      '&$cssFocused': {
        color: '#333333',
      },
      color: '#333333'
    },
    cssFocused: {},
  };
};

const FilterAdmissions = (props) => {

  const [ dateState, setDateState ] = useState({ 
    date: "", 
    startDate: "", 
    startTime: "", 
    endDate: "", 
    endTime: "" 
  });
  const [ resetAuto, setResetAuto ] = useState(false);
  const { classes } = props;
  const [ professionaOptions, setProfessionalOptions ] = useState([]);
  const [ modalityOptions, setModalityOptions ] = useState([]);

  useEffect(() => {
      props.fetchModalitysIfNeeded();
      props.fetchProfessionalsIfNeeded();
      props.fetchServicesIfNeeded();
  }, []) 

  const clearForm = () => {
    const { resetAdmissions } = props;
    setDateState({
      date: '',
      startDate: '',
      startTime: '',
      endDate: '',
      endTime: '',
    });
    formik.resetForm();
    resetAdmissions();
    setResetAuto(true)
  }

  const clearReset = () => {
    setResetAuto(false)
  }

  const handleSubmitForm = (formikValues) => {
    
    let query = '?';

    if (formikValues) {

      Object.keys(formikValues).map((key) => {

        if (key === 'externalAdmissionId') {
          if(formikValues[key]){
            query = `${query}&externalAdmissionId=${formikValues[key]}`;
            return query;
          }
        }
        if (key === 'documentNumber') {
          if(formikValues[key]){
            query = `${query}&documentNumber=${formikValues[key]}`;
            return query;
          }
        }

        if (key === 'reportState') {
          if(formikValues[key]){
            query = `${query}&reportState=${formikValues[key]}`;
            return query;
          }
        }

        if (key === 'startDate') {
          if(formikValues[key]){
            query = `${query}&entryDatetime>=${moment(`${formikValues[key]} ${formikValues['startTime'] ? formikValues['startTime'] : ""}`, "YYYY-MM-DD HH:mm:ss").format(formikValues['startTime'] ? "YYYY-MM-DD HH:mm:ss" : "YYYY-MM-DD")}`;
            return query;
          }
        }

        if (key === 'endDate') {
          if(formikValues[key]){
            if(formikValues['endTime']){
              query = `${query}&entryDatetime<=${moment(`${formikValues[key]} ${formikValues['endTime']}`, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss")}`;
            } else {
              query = `${query}&entryDatetime<=${moment(`${formikValues[key]}`, "YYYY-MM-DD HH:mm:ss").add(1,'days').format("YYYY-MM-DD HH:mm:ss")}`;
            }
            return query;
          }
        }

        if(key === 'requestor' || key === 'entryType' || key === 'modalityId') {
          if(formikValues[key]) {
            query = `${query}&${key}=/${formikValues[key]}/i`;
            return query;
          }
        }

        if(key === 'professionalId'){

            if(formikValues["professionalId"]) {
              query = `${query}&professionalId=${formikValues[key].id}`;
              return query;
            }

        }
      });

      return props.getAdmissionsFilter(query, 0, 3);
    }

    props.getAdmissionsFilter(query, 0, 3);
  }

  const formik = useFormik({
    initialValues: {
      "externalAdmissionId": "",
      "documentNumber": "",
      "requestor": "",
      "professionalId": "",
      "entryType": "",
      "modalityId": "",
      "reportState": "",
      "startDate": "",
      "startTime": "",
      "endDate": "",
      "endTime": "",
    },
    onSubmit: handleSubmitForm
  })

  const toggleDate = (key) => {

    const { change } = props;

    const todayStart = moment().hours(0).minutes(0).seconds(0).milliseconds(0);
    const todayEnd = moment().hours(23).minutes(59).seconds(59).milliseconds(0);

    const yesterdayStart = todayStart.clone().subtract(1, 'days');
    const yesterdayEnd = todayEnd.clone().subtract(1, 'days');

    const today = moment().hours(0).minutes(0).seconds(0).milliseconds(0);
    const yesterday = today.clone().subtract(1, 'days');

    if (dateState.date === key) {

      change("startDate", "");
      change("endDate", "");
      change("startTime", "");
      change("endTime", "");

      setDateState({
        date: '',
        startDate: '',
        startTime: '',
        endDate: '',
        endTime: ''
      });

    } else {

      switch(key) {
        case 'today':
          setDateState({
            date: dateState.date,
            startDate: today.format(),
            startTime: todayStart.format('MM-DD-YYYY HH:mm'),
            endDate: today.format('MM-DD-YYYY'),
            endTime: todayEnd.format('MM-DD-YYYY HH:mm'),
          });
          formik.setFieldValue('startDate', today.format('YYYY-MM-DD'));
          formik.setFieldValue('startTime', todayStart.format('HH:mm:ss'));
          formik.setFieldValue('endDate', today.format('YYYY-MM-DD'));
          formik.setFieldValue('endTime', todayEnd.format('HH:mm:ss'));
        break;
        case 'yesterday':
          setDateState({
            date: dateState.date,
            startDate: yesterday.format('MM-DD-YYYY'),
            startTime: yesterdayStart.format('MM-DD-YYYY HH:mm'),
            endDate: yesterday.format('MM-DD-YYYY'),
            endTime: yesterdayEnd.format('MM-DD-YYYY HH:mm'),
          });
          formik.setFieldValue('startDate', yesterday.format('YYYY-MM-DD'));
          formik.setFieldValue('startTime', yesterdayStart.format('HH:mm:ss'));
          formik.setFieldValue('endDate', yesterday.format('YYYY-MM-DD'));
          formik.setFieldValue('endTime', yesterdayEnd.format('HH:mm:ss'));
        break;
        case 'week':
          setDateState({
            date: dateState.date,
            startDate: today.clone().subtract(7, 'days').format('MM-DD-YYYY'),
            startTime: todayStart.clone().subtract(7, 'days').format('MM-DD-YYYY HH:mm'),
            endDate: today.format('MM-DD-YYYY'),
            endTime: todayEnd.format('MM-DD-YYYY HH:mm')
          });
          formik.setFieldValue('startDate', today.clone().subtract(7, 'days').format('YYYY-MM-DD'));
          formik.setFieldValue('startTime', todayStart.clone().subtract(7, 'days').format('HH:mm:ss'));
          formik.setFieldValue('endDate', today.format('YYYY-MM-DD'));
          formik.setFieldValue('endTime', todayEnd.format('HH:mm:ss'));
        break;
      }

      setDateState({
        ...dateState,
        date: key
      });

    }
  }

  useEffect(() => {
    if((props.professionals && props.professionals.length) || (props.services && props.services.length)){
      const { professionals, services } = props;
      const newProfessionals = professionals.map(item => ({ label: item.name, value: { id: item.id, isA: "professional" } }));
      const newServices = services.map(item => ({ label: item.name, value: { id: item.id, isA: "service" } }));
      const newOptions = newProfessionals.concat(newServices);
      setProfessionalOptions(newOptions);
    }
  }, [ props.professionals, props.services ])

  useEffect(() => {
    if(props.modalitys && props.modalitys.length){
      const { modalitys } = props;
      const newModalitys = modalitys.map(item => ({ label: item.modality, value: item.id }));
      setModalityOptions(newModalitys);
    }
  }, [ props.modalitys ])

  return (
    <Grid className={classes.root}>
      <FormikProvider value={formik}>
        <Grid container>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={12} sm={3}>
              <Field
                name="externalAdmissionId"
                component={CustomTextField}
                label="Nro. Informe"
                type="text"
                labelstyle={{ root: classes.cssLabel }}
                functionKeyPress={handleSubmitForm}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Field
                name="documentNumber"
                component={CustomTextField}
                label="Documento"
                type="text"
                labelstyle={{ root: classes.cssLabel }}
                functionKeyPress={handleSubmitForm}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="requestor"
                component={CustomTextField}
                label="Nombre y Apellido"
                type="text"
                labelstyle={{ root: classes.cssLabel }}
                functionKeyPress={handleSubmitForm}
              />
            </Grid>
          </Grid>
          <Grid className={classes.marginTop} container item xs={12} spacing={2}>
            <Grid item xs={12} sm={3}>
              <Field
                name="professionalId"
                component={CustomAutocomplete}
                options={professionaOptions}
                placeholder="Profesional o servicio a cargo"
                resetAuto={resetAuto}
                clearReset={clearReset}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Field
                name="entryType"
                component={CustomAutocomplete}
                placeholder="Tipo de Ingreso"
                options={[
                  {label: "---", value: null},
                  {label: "NORMAL", value: "normal"},
                  {label: "URGENCIA", value: "urgencia"}
                ]}
                resetAuto={resetAuto}
                clearReset={clearReset}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Field
                name="modalityId"
                component={CustomAutocomplete}
                placeholder="Tipo de Modalidad"
                options={modalityOptions}
                resetAuto={resetAuto}
                clearReset={clearReset}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Field
                name="reportState"
                component={CustomAutocomplete}
                placeholder="Estado"
                options={[
                  {label: "---", value: null},
                  {label: "ADMITIDO", value: "admitted"},
                  {label: "EN PROCESO", value: "inProcess"},
                  {label: "DERIVADO", value: "derivative"},
                  {label: "FIRMADO", value: "signed"},
                  {label: "REALIZADO", value: "accomplished"},
                  {label: "PARA RETIRAR", value: "retire"},
                  {label: "FINALIZADO", value: "finished"},
                  {label: "CANCELADO", value: "cancelled"}
                ]}

                resetAuto={resetAuto}
                clearReset={clearReset}
              />
            </Grid>
          </Grid>
          <Grid className={classes.marginTop} container item xs={12} spacing={2}>
            <Grid item xs={8} sm={2}>
              <Field
                name="startDate"
                component={CustomTextField}
                label="Fecha desde"
                type="date"
                props={{
                  disabled: dateState.date !== ""
                }}
                labelstyle={{ root: classes.cssLabel }}
                shrink
              />
            </Grid>
            <Grid item xs={4} sm={2}>
              <Field
                name="startTime"
                component={CustomTextField}
                label="Hora desde"
                type="time"
                props={{
                  disabled: dateState.date !== "" || dateState.startDate !== ""
                }}
                labelstyle={{ root: classes.cssLabel }}
                shrink
              />
            </Grid>
            <Grid item xs={8} sm={2}>
              <Field
                name="endDate"
                component={CustomTextField}
                label="Fecha desde"
                type="date"
                props={{
                  disabled: dateState.date !== ""
                }}
                labelstyle={{ root: classes.cssLabel }}
                shrink
              />
            </Grid>
            <Grid item xs={4} sm={2}>
              <Field
                name="endTime"
                component={CustomTextField}
                label="Hora hasta"
                type="time"
                props={{
                  disabled: dateState.date !== "" || dateState.endDate !== ""
                }}
                labelstyle={{ root: classes.cssLabel }}
                shrink
              />
            </Grid>
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                name="btnToday"
                variant={dateState.date === 'today' ? 'contained' : 'outlined'}
                size="small"
                color="primary"
                className={classes.button}
                onClick={(e) => toggleDate('today')}
              >Hoy
              </Button>
            </Grid>
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                name="btnYesterday"
                variant={dateState.date === 'yesterday' ? 'contained' : 'outlined'}
                size="small"
                color="primary"
                className={classes.button}
                onClick={(e) => toggleDate('yesterday')}
              >Ayer
              </Button>
            </Grid>
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                name="btnWeek"
                variant={dateState.date === 'week' ? 'contained' : 'outlined'}
                size="small"
                color="primary"
                className={classes.button}
                onClick={(e) => toggleDate('week')}
              >Semana
              </Button>
            </Grid>
          </Grid>
          <Grid container item xs={12} align="right" justify="flex-end">
            <Button
              color="secondary"
              labelstyle={{ root: classes.cssLabel }}
              type="button"
              onClick={() => clearForm()}
            >
              <ClearAll style={{ marginRight: 10 }} />Limpiar
            </Button>
            <Button
              color="secondary"
              onClick={formik.handleSubmit}
              labelstyle={{ root: classes.cssLabel }}
            >
              <FilterList style={{ marginRight: 10 }} />Filtrar
            </Button>
          </Grid>
        </Grid>
      </FormikProvider>
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    branchOffices: state.branchOffice,
    modalitys: state.modalitys.items,
    professionals: state.professionals.items,
    services: state.services.items,
    professional: state.professionals.itemId,
    professionalAuth: state.auth.professionalAssigned,
    userId: state.auth.userID
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getAdmissionsFilter,
    fetchModalitysIfNeeded,
    fetchProfessionalsIfNeeded,
    fetchServicesIfNeeded,
    resetAdmissions,
    getProfessionalById
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FilterAdmissions));
