import React, { useState, useRef, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import config from '../config/config';
import Search from '@material-ui/icons/Search';
import Paper from "@material-ui/core/Paper";

import { makeStyles } from '@material-ui/core';


import {
  useParams
} from "react-router-dom";

import { isMobile } from "react-device-detect";

import Admission from './admissions/Admission';
import ErrorShow from './errors/ErrorShow';
import SiginLayout from "./SiginLayout";
import SignupLayout from "./SignupLayout";
import ForgottenLayout from "./ForgottenLayout";
import ResetPassLayout from "./auth/ResetPassLayout";


const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(2)
	},
	typography: {
		fontSize: isMobile ? 14 : 16
	},
    layoutPaper: {
      padding: theme.spacing(2),
	  [ theme.breakpoints.up('md') ]: {
		minHeight: "30vh",
		
	  }
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    textFields: {
		display: true,
		alignItems: "center",
		justifyContent: "center",
		paddingBottom: theme.spacing(2),
		marginLeft: theme.spacing(1),
		marginTop: theme.spacing(3),
		marginBottom: isMobile ? theme.spacing(1) : theme.spacing(6)
    }
}))

function Layout(props) {

	const [ fetching, setFetching ] = useState(false);
	const [ fetchError, setfetchError ] = useState(false);
	const [ admission, setAdmission ] = useState(null);
	const [ errorType, setErrorType ] = useState(null);
	const [ admRequired, setAdmRequired ] = useState(false);
	const [ docRequired, setDocRequired ] = useState(false);
	const [ authRender, setAuthRender ] = useState("signin");

	const classes = useStyles();

	const { id, document: documentFromParams, admission: admissionFromParams } = useParams();

	const admissionFromEmail = id;

	const inputDoc = useRef(null);
	const inputAdm = useRef(null);

	const getAdmission = async () => {

		try {

			let response = await axios.get(config.apiURL+"/api/admission/fromemail/"+admissionFromEmail);

			setAdmission(response.data);
			setFetching(false);

		} catch(error) {

			console.log(error);

		}

	}

	const getByDocAndAdmission = async (doc, adm) => {

		try {

			let response = await axios.get(config.apiURL+"/api/admission/fromextern/"+doc+"/"+adm);

			setAdmission(response.data);
			setFetching(false);

		} catch(error) {

			console.log(error);

		}


	}

	useEffect(() => {

		const { pathname } = props.history.location;

		if(pathname === "/signup"){
			setAuthRender("signup")
		} else if(pathname === "/forgotten") {
			setAuthRender("forgotten")
		} else if (pathname === "/passreset") {
			setAuthRender("passreset")
		} else {
			setAuthRender("signin")
		}

    	if(admissionFromEmail && admissionFromEmail.length > 0) {

			setFetching(true);
			getAdmission()

		} else {

			if(documentFromParams && admissionFromParams){

				setFetching(true);
				getByDocAndAdmission(documentFromParams, admissionFromParams);

			}

		}

	}, []);

	const searchResult = async (doc, adm) => {

		setFetching(true);

		if(doc.length === 0) {
			setDocRequired(true);
			setErrorType(1);
		} else {
			setDocRequired(false);
		}

		if (adm.length === 0) {
			setAdmRequired(true);
			setErrorType(1);
		} else {
			setAdmRequired(false);
		}

		if(doc.length > 0 && adm.length > 0) {

			setDocRequired(false);
			setAdmRequired(false);

			try {

				let response = await axios.get(config.apiURL+"/api/admission/fromextern/"+doc+"/"+adm);

				if(response.data.length > 0) {
					setfetchError(false);
					setFetching(true);
					setAdmission(response.data);
				} else {
					setfetchError(true);
					setErrorType(1);
				}

			} catch(error) {

				if(error.response && error.response.status === 404) {
					setfetchError(true);
					setErrorType(1);
				} else {

					console.log(error);
					setfetchError(true);
					setErrorType(3);
				}

			}

		}

		setFetching(false);

	}

	let AdmissionComponent = admission && admission.length ? <React.Fragment>{ admission.map((adm) => adm ? <Admission classes={classes} admission={adm} /> : null) }</React.Fragment> : null;
	let ErrorComponent = fetchError ? <ErrorShow type={errorType} classes={classes} /> : null;

	return (
		<Grid container className={ classes.root } >
			<Grid item xs={12}>
				{/* <Grid container spacing={1}>
					<Grid item xs={12} >
						<Typography variant="body1" gutterBottom align="left" style={{ marginBottom: 10 }}>
							Bienvenido al sistema de consulta de resultados de { config.appName }.
						</Typography>
					</Grid>
				</Grid> */}
				<Grid container spacing={3} style={{ display: "flex", justifyContent: "center" }}>
					<Grid item xs={12} sm={12} md={admission ? 10 : 6}>
						{/* <Paper className={classes.layoutPaper}> */}
							{ !admissionFromEmail &&
							<React.Fragment>
								<Grid 
									style={{ 
										display: "flex", 
										flexDirection: "column", 
										justifyContent: "center", 
										alignItems: "center", 
										paddingTop: "20px", 
										paddingLeft: "20px", 
										paddingRight: "20px", 
										marginBottom: "-50px", 
										height: admission && admission.length ? "auto" : "79vh"
									}}
								>
									
									<Grid container spacing={isMobile ? 1 : 3} className={classes.textFields} style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%"}}>

										<Grid item xs={12} align="center">
											<img width={isMobile? 128 : 192 } height={isMobile ? 37 : 56 }  src={`${process.env.REACT_APP_STATIC_IMAGES_URL}/logoCliente.png`} />
										</Grid>
										<Grid item xs={12} align="center">
											<Typography variant="h6" style={{ fontWeight: 'bold'}}>
												Consultar Resultados
											</Typography>
										</Grid>
										<Grid container item xs={12} sm={admission ? 4 : 6} spacing={3}>
											<Grid item xs={12}>
												<TextField 
													fullWidth
													error={docRequired}
													inputRef={inputDoc}
													autoFocus={true}
													id={"identity_document"}
													label="Nro. Documento"
													variant="outlined"
													helperText={ docRequired ? "El campo es requerido":""} 
												/>
											</Grid>
											<Grid item xs={12}>
												<TextField 
													fullWidth
													error={admRequired}
													inputRef={inputAdm}
													id={"admission_name"}
													label="Nro. Informe"
													variant="outlined"
													helperText={admRequired? "El campo es requerido" : "" } 
												/>
											</Grid>
											<Grid item xs={12} style={{ textAlign: "center" }}>
												<Button
													variant="contained"
													size="large"
													color="primary"
													aria-label="add"
													style={{ width: "100%" }}
													className={classes.margin}
													onClick={()=>searchResult(inputDoc.current.value, inputAdm.current.value)}
													disabled={fetching}
												>
													{ fetching ? <CircularProgress size={24} className={classes.extendedIcon} />:<Search className={classes.extendedIcon} /> }
													Consultar
												</Button>
											</Grid>
										</Grid>
									</Grid>
									{
										ErrorComponent
									}
								</Grid>
							</React.Fragment>
						}
						{
							!fetching && AdmissionComponent
						}
						{
							fetching && admissionFromEmail && admissionFromEmail.length > 0 && <CircularProgress />
						}
						{/* </Paper> */}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

export default Layout;
