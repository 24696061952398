import React, { useState } from 'react';

import moment from 'moment';
import axios from 'axios';

import { WhatsappShareButton, WhatsappIcon } from 'react-share';

import { isMobile } from "react-device-detect";

import config from '../../config/config';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';

import Description from '@material-ui/icons/Description';
import AspectRatio from '@material-ui/icons/AspectRatio';
import ShareIcon from '@material-ui/icons/Share';
import GetAppIcon from '@material-ui/icons/GetApp';

import CustomSnackBar from '../commons/CustomSnackBar';
import ModalShare from '../commons/ModalShare';

function getTimeElapsed(study) {

	const dateNow =moment();
    const end = moment.utc(study.entryDatetime, 'YYYY/MM/DD HH:mm:ss').format('YYYY/MM/DD HH:mm:ss');
    const localTime = moment.utc(end, 'YYYY/MM/DD HH:mm:ss');
    const duration = moment.duration(dateNow.diff(localTime));

    return `${Math.floor(duration.asDays())} d ${duration.hours()} h ${duration.minutes()} m`;
}

function Study(props) {

	const {classes, study} = props;

	const [open, setOpen] = useState(false);
	const [share, setShared] = useState({shared: false, emailToShare: null, severity: "success"});
	const [fetching, setFetching] = useState(false);

	const timeElapsed = getTimeElapsed(study);

	const reportStateView = () => {
      	if (study.state === 'derivative') return 'DERIVADO';
      	if (study.state === 'inProcess') return 'EN PROCESO';
      	if (study.state === 'signed') return 'FIRMADO';
      	if (study.state === 'accomplished') return 'REALIZADO';
      	if (study.state === 'retire') return 'PARA RETIRAR';
      	if (study.state === 'finished') return 'FINALIZADO';
      	if (study.state === 'cancelled') return 'CANCELADO';
      	return 'ADMITIDO';
    };

    const handleOpenModalShare = () => {
    	setOpen(true);
    }

    const sendEmail = async (url, data) => {

    	setFetching(true)
    	let response = await axios.post(url, data);

    	return response;

    }

    const handleCloseModalShare = (action, emailToShare = null) => {

    	if(action) {

    		let response = sendEmail(config.apiURL+"/api/study/share/"+study.id, { email: emailToShare }).then((response) => {

    			if(response.data) {

	    			setShared({shared: true, message: "Se envió correctamente el email a la dirección: "+emailToShare, emailToShare: emailToShare, severity: "success"});

	    		} else {

	    			setShared({shared: true, message:"Ocurrió un error al intentar enviar el email. Por favor intente más tarde.", emailToShare: emailToShare, severity: "error"});

	    		}

    			setFetching(false);

    		});

    	}

    	setOpen(false);

    }

    const handleSnakClose = (state) => {

    	setShared({ shared: state, message: null, emailToShare: null, severity: "success"});

    }

	const handleValidateInstanceUids = async (studyId, uids) => {
		try {
			const pacsresponse = await axios.get(`${process.env.REACT_APP_PACSAPI_URL}/api/study/${studyId}/${uids}`);
			if(pacsresponse.status === 200) {
				window.open(`${process.env.REACT_APP_VIEWPORT_CONFIG}/${uids}`);
			}
		} catch (error) {
			if(error.response && error.response.status === 404){
				setShared({ shared: true, message: "EL SISTEMA ESTÁ RECUPERANDO EL ESTUDIO SOLICITADO. POR FAVOR INTENTE EN UNOS MINUTOS", emailToShare: null, severity: "warning"});
			} else {
				setShared({ shared: true, message: "OCURRIÓ UN ERROR A NIVEL DEL SISTEMA, POR FAVOR CONTACTE AL ADMINISTRADOR", emailToShare: null, severity: "error"});
			}
		}
	}

	return (
		<React.Fragment>
			<CustomSnackBar severity={ share.severity }
							open={ share.shared }
							message={ share.message }
							setExternalOpen={ handleSnakClose }
			/>
			<ModalShare open={open}
						handleCloseModalShare={handleCloseModalShare}
						fetching={fetching}
			/>
			<Card className={classes.card}>
				<CardHeader
					className={classes.cardHeader}
			        title={
			        	<Typography className={classes.cardTitle} variant="h1" style={{ fontWeight: 'bold' }} gutterBottom align="left">
		                	Datos del estudio
		                </Typography>
		            }
			        subheader={
			        	<Typography className={classes.cardSubTitle} variant="h1" gutterBottom align="left">
		                	{"Fecha de admisón: "+moment.utc(study.entryDatetime).local().format('DD/MM/YYYY - HH:mm')}
		                </Typography>
		            }
		            action={
		            	isMobile ? 
						<React.Fragment>
							<Grid container >
								<Grid item xs={6}>
									<IconButton
										style={{paddingTop:25}}
										color="primary"
										aria-label="settings"
										onClick={()=>handleOpenModalShare()}
									>
										<ShareIcon className={classes.extendedIcon}/>
									</IconButton>
								</Grid>
								<Grid item xs={6}>
									<WhatsappShareButton style={{paddingTop:20}} url={ `${config.portalURL}/study/${study.id}` } >
										<WhatsappIcon round={true} size={36} className={classes.extendedIcon}/>
									</WhatsappShareButton>
								</Grid>
							</Grid>
						</React.Fragment> :
						<React.Fragment>
							<Button
								variant="contained"
								size="small"
								color="primary"
								aria-label="add"
								className={classes.shareButton}
								onClick={()=>handleOpenModalShare()}
							>
								<ShareIcon className={classes.extendedIcon}/>
								Compartir
							</Button>
						</React.Fragment>
			        }
			    />
			    <CardContent>
					<Grid className={classes.root}>
			            <Grid container spacing={2}>
			              <Grid item xs={12} sm={4} className={classes.cell}>
			                <Typography className={classes.typography} variant="body1" style={{ fontWeight: 'bold' }} gutterBottom align="left">
			                  Nro. Informe: {study.externalAdmissionId}
			                </Typography>
			              </Grid>
			              <Grid item xs={12} sm={4} className={classes.cell}>
			                <Typography className={classes.typography} variant="body1" style={{ fontWeight: 'bold' }} gutterBottom align="left">
			                  Tiempo transcurrido: {timeElapsed}
			                </Typography>
			              </Grid>
			              <Grid item xs={12} sm={4} className={classes.cell}>
			                <Typography className={classes.typography} variant="body1" gutterBottom align="left">
			                  Documento: {study.documentNumber}
			                </Typography>
			              </Grid>
			            </Grid>
			            <Grid container spacing={2}>
			              <Grid item xs={12} sm={4} className={classes.cell}>
			                <Typography className={classes.typography} variant="body1" gutterBottom align="left">
			                  Paciente: {study.requestor.toUpperCase()}
			                </Typography>
			              </Grid>
			              <Grid item xs={12} sm={4} className={classes.cell}>
			                <Typography className={classes.typography} variant="body1" gutterBottom align="left">
			                  Sede: {study.location}
			                </Typography>
			              </Grid>
			              <Grid item xs={12} sm={4} className={classes.cell}>
			                <Typography className={classes.typography} variant="body1" gutterBottom align="left">
			                  Tipo de Ingreso: {study.entryType.toUpperCase()}
			                </Typography>
			              </Grid>
			            </Grid>
			            <Grid container spacing={2}>
			              <Grid item xs={12} sm={4} className={classes.cell}>
			                <Typography className={classes.typography} variant="body1" gutterBottom align="left">
			                  Solicitante: {study.solicite && study.solicite.toUpperCase()}
			                </Typography>
			              </Grid>
			              <Grid item xs={12} sm={4} className={classes.cell}>
			                <Typography className={classes.typography} variant="body1" gutterBottom align="left">
			                  Recurso: {study.professional.toUpperCase()}
			                </Typography>
			              </Grid>
			              <Grid item xs={12} sm={4} className={classes.cell}>
			                <Typography className={classes.typography} variant="body1" gutterBottom align="left" style={{ fontWeight: 'bold' }}>
			                  Tipo de Estudio: {study.examType}
			                </Typography>
			              </Grid>
			            </Grid>
			            <Grid container spacing={2}>
			              <Grid item xs={12} sm={4} className={classes.cell}>
			                <Typography className={classes.typography} variant="body1" gutterBottom align="left" style={{ fontWeight: 'bold' }}>
			                  Estado: {reportStateView()}
			                </Typography>
			              </Grid>
			              <Grid item xs={12} sm={4} className={classes.cell}>
			                <Typography className={classes.typography} variant="body1" gutterBottom align="left">
			                  Modalidad: {study.modality}
			                </Typography>
			              </Grid>
			            </Grid>
			        </Grid>
		        </CardContent>
		        <CardActions>
			        <Grid container spacing={2} align="center">
						<Grid item xs={12} sm={6} className={classes.buttonResult}>
							<Button
								variant="contained"
								size="large"
								color="primary"
								aria-label="add"
								className={classes.margin}
								disabled={!study.studyInstanceUids}
								onClick={()=> handleValidateInstanceUids(study.medexwareRisStudyId, study.studyInstanceUids)}
								
								// onClick={()=>{
								// 		window.open(`${process.env.REACT_APP_VIEWPORT_CONFIG}/${study.studyInstanceUids}`);
								//}}							
							>
								<AspectRatio className={classes.extendedIcon}/>
								Ver estudio
							</Button>
						</Grid>
				        <Grid item xs={12} sm={6} className={classes.buttonResult}>
					        <Button
					          	variant="contained"
					          	size="large"
					          	color="primary"
					          	aria-label="add"
					          	className={classes.margin}								  
					          	disabled={!study.studyResultURL || study.studyResultURL === "blank" || isMobile}
					          	//onClick={()=> handleValidateInstanceUids(study.medexwareRisStudyId, study.studyInstanceUids)}
								onClick={()=>{window.open(study.studyResultURL);}}
							
							>
					        	<GetAppIcon className={classes.extendedIcon}/>
					          	Descargar estudio
					        </Button>
				        </Grid>
	            	</Grid>
		        </CardActions>
	        </Card>
        </React.Fragment>
	);
}

export default Study;
