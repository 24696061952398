import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const stylesMaterial = () => ({
  button: {
    width: '100%'
  }
});

class MoreButton extends Component {
  render() {
    const {
      classes, getItems, skip, limit, query
    } = this.props;
    return (
      <div>
        <Button
          size="medium"
          className={classes.button}
          onClick={() => {
            getItems(query, skip, limit);
          }}
        >
          <KeyboardArrowDownIcon />
          Ver más
        </Button>
      </div>
    );
  }
}

const StyledMoreButton = withStyles(stylesMaterial)(MoreButton);

export default StyledMoreButton;
