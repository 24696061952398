import genericActions from "./generic.actions";

const actions = genericActions({
  modelToAction: "AGENDA_TURN_STATE",
  modelToRoute: "agendaTurnState",
  modelToState: "agendaTurnStates",
  axiosBaseURL: process.env.REACT_APP_MEDEXWARE_APPOINTMENT_URL,
  authToken: "appointmentToken"
});

export default actions;