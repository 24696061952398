import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Person from '@material-ui/icons/PersonPin';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Menu from '@material-ui/core/MenuList';
import Typography from '@material-ui/core/Typography';
import DraftsIcon from '@material-ui/icons/Drafts';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import VpnKey from '@material-ui/icons/VpnKey';
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';

const styles = () => {
  return {
    icon: {
      color: 'white',
      fontSize: 30,
      marginRight: "-20px"
    },
    text: {
      color: 'white',
      fontWeight: 500,
      fontSize: 14,
    },
    root: {
      padding: 0,
    },
    rootContent: {
      marginLeft: 40,
      width: 'unset'
    },
    content: {
      display: 'flex',
      justifyContent: 'flex-end'
    }
  };
};

class UserLayout extends React.Component {

  constructor(props) {
    super(props);

    this.prevOpen = false;

    this.state = {
      anchorEl: null,
      open: false
    };

    this.handleToggle = this.handleToggle.bind(this);
    this.handleListKeyDown = this.handleListKeyDown.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleClickAway = this.handleClickAway.bind(this);
    this.inDeleteToken = this.inDeleteToken.bind(this);
  }

  handleToggle(event) {
    this.setState({anchorEl: event.currentTarget, open: !this.state.open});
  };

  handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      this.setState({anchorEl: event.currentTarget, open: !this.state.open});
    }
  }

  handleClose() {
    this.setState({anchorEl: null, open: false});
  };

  handleClickAway() {
    this.setState({anchorEl: null, open: false});
  }

  inDeleteToken() {
    this.props.history.push("/signin");
    this.props.deleteToken();
  }

  render() {

    const { classes, deleteToken, handleModalClose, handleModalContactClose } = this.props;

    const widthDropDown = this.props.userName.length*3+100;

    return (
      <div className={classes.content}>
        <Button
          style={{ width: widthDropDown }}
          className={classes.root}
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={this.handleToggle}
        >
          <ListItem classes={{ root: classes.rootContent }}>
            <ListItemIcon className={classes.icon}>
              <Person />
            </ListItemIcon>
            <ListItemText primary={this.props.userName.split("@")[0]} classes={{ primary: classes.text, root: classes.root }} />
            <ListItemIcon>
              <ArrowDropDown className={classes.icon} />
            </ListItemIcon>
          </ListItem>
        </Button>
        <Popper open={this.state.open} anchorEl={this.state.anchorEl} transition disablePortal>
          <ClickAwayListener onClickAway={this.handleClickAway}>
            <Paper>
              <Menu
                id="simple-menu"
                anchorEl={this.state.anchorEl}
                keepMounted
                open={this.state.open}
                onClose={this.handleClose}
              >
                <MenuItem onClick={handleModalContactClose}>
                  <ListItemIcon>
                    <AlternateEmailIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="inherit" noWrap>
                    Datos de contacto
                  </Typography>
                </MenuItem>
                <MenuItem onClick={handleModalClose}>
                  <ListItemIcon>
                    <VpnKey fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="inherit" noWrap>
                    Cambiar Contraseña
                  </Typography>
                </MenuItem>
                <MenuItem onClick={this.inDeleteToken}>
                  <ListItemIcon>
                    <PowerSettingsNew fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="inherit" noWrap>
                    Cerrar Sesión
                  </Typography>
                </MenuItem>
              </Menu>
            </Paper>
          </ClickAwayListener>
        </Popper>
      </div>
    )
  }
};

const UserLayoutStyled = withStyles(styles)(UserLayout);

export default UserLayoutStyled;
