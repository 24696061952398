import { combineReducers } from 'redux';
import auth from './auth';
import globals from './globals';
import admissions from './admission';
import agendaTurns from './agendaTurn';
import services from './service';
import professionals from './professional';
import modalitys from './modality';
import branchOffices from './branchOffice';
import resources from './resource';
import agendaTypes from './agendaType';
import agendaTurnStates from './agendaTurnState';
import appointmentStates from './appointmentState';
import founders from './funder';
import appointments from './appointment';
import users from './user';

import { DELETE_TOKEN } from '../actions/auth';

const appReducer = combineReducers({
  auth,
  globals,
  agendaTurns,
  admissions,
  services,
  professionals,
  modalitys,
  branchOffices,
  resources,
  agendaTypes,
  agendaTurnStates,
  appointmentStates,
  founders,
  appointments,
  users
});

const rootReducer = (state, action) => {
  if (action.type === DELETE_TOKEN) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
