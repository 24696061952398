import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { confirmEmailToken } from '../../actions/auth';

const ConfirmEmailToken = (props) => {

  const [redirect , setRedirect] = useState({
    go: false,
    params: {
      pathname: '/signin',
      state: {
        from: "confirmEmail",
        message: "Ocurrió un error al verificar el email, por favor proceda a solicitar nuevamente su token de confirmación"
      }
    }
  });

  useEffect(()=>{
    if(props.match && props.match.params.token){
        const { token } = props.match.params;
        props.confirmEmailToken(token).then(res => {
          if(res.value.data.message){
              setRedirect({
                  go: true,
                  params: {
                      pathname: "/signin",
                      state: {
                          from: "confirmEmail",
                          message: res.value.data.message
                      }
                  }
              });
          } else {
              setRedirect({
                  go: true,
                  params: {
                      pathname: '/signin',
                      state: {
                          message: "Ocurrió un error al verificar el email, por favor proceda a solicitar nuevamente su token de confirmación",
                          from: "confirmEmail"
                      }
                  }
              })
          }
      });
    }
  }, []);

  return (redirect.go ?
    <Redirect to={redirect.params}/> :
    <Grid container
          direction="row"
          justify="center"
          alignItems="center"
          style={{ width: "100vw", height: "100vh" }}
    >
      <Grid item xs={12} align="center">
        <CircularProgress />
        <Typography>Verificando, por favor espere ...</Typography>
      </Grid>
    </Grid>
  );
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    confirmEmailToken
  }, dispatch);
}

const ConfirmEmailTokenConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmEmailToken);

export default ConfirmEmailTokenConnected
