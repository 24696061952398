import React, { useState, useEffect, useRef } from 'react';
import { Field, FormikProvider, useFormik } from 'formik';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import SaveIcon from '@material-ui/icons/Save';

import CustomDialog from "./CustomDialog";
import CustomTextField from "./form/CustomTextField";

import userActions from '../../actions/user';
import { setSnack } from "../../actions/globals";

const stylesMaterial = theme => ({
  root: {
    padding: theme.spacing(2)
  },
});

const validate = (values) => {
  const errors = {};

  const validateEmail = (requestorEmail) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(requestorEmail).toLowerCase());
  };

  const requiredFields = [
    'email'
  ];

  requiredFields.forEach((field) => {
    if(field === "email" && values[field] && !validateEmail(values[field])) {
      errors[field] = 'Dirección de correo inválida';
    }
    if (!values[field] || !values[field].length) {
      errors[field] = 'Campo requerido';
    }
  });
  return errors;
};

const ChangeContactInfoDialog = (props) => {

  const { open, handleModalClose } = props;

  const formik = useFormik({
     initialValues: {
       email: '',
     },
     onSubmit: (values, actions) => handleContactInfoSubmit(values, actions)
   });

  useEffect(() => {
    formik.setValues({
      email: props.auth.email
    });
    formik.setTouched(["email"]);
  }, []);

  useEffect(() => {
    formik.setFieldValue("email", props.auth.email);
    formik.setTouched(["email"]);
  }, [props.auth.email])


  const handleContactInfoSubmit = (values, actions) => {

    const data = {
      ...values,
      userId: props.auth.id
    };

    if(props.auth.email !== values.email) {
      props.changeContactInfo(data).then((response) => {
         props.handleModalClose();
         props.setSnack({
           open: true,
           message: "Se modificó correctamente la información de contacto",
           title: "Modificación de información de contacto",
           severity: "success"
         });
      });
    } else {
      props.handleModalClose();
    }
  }

  const title = <Typography variant="h5">
                  { "Modificar información de contacto" }
                </Typography>;

  const actions = <FormikProvider value={formik}>
                    <Button
                      onClick={() => {
                        formik.resetForm();
                        handleModalClose();
                      }}
                      style={{ width: 150, height: 43 }}
                    >Cancelar
                    </Button>
                    <Button
                      variant="outlined"
                      style={{ marginLeft: 15, width: 150 }}
                      color="primary"
                      onClick={formik.handleSubmit}
                      disabled={(props.userLoading || !formik.isValid)}
                    >
                      {props.userLoading ? <CircularProgress style={{ marginRight: 8 }} size={14} /> : <SaveIcon style={{ marginRight: 8 }} />}
                      Guardar
                    </Button>
                  </FormikProvider>;

  const content = <FormikProvider value={formik}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Field
                          name="email"
                          component={CustomTextField}
                          label="Email"
                          type="text"
                          showError
                        />
                      </Grid>
                    </Grid>
                  </FormikProvider>

  return (
    <CustomDialog
      handleClose={handleModalClose}
      handleCloseParams={[]}
      open={open}
      title={title}
      actions={actions}
      content={content}
      maxWidth="sm"
      fullWidth
    />
  );
}

const mapDispatchToProps = (dispatch) => {
  const { changeContactInfo } = userActions.actions;
  return bindActionCreators({
    changeContactInfo,
    setSnack
  }, dispatch);
};

const mapStateToProps = (state) => {
  return {
    userLoading: state.users.isFetching,
    auth: state.auth
  };
};

const styleMaterial = withStyles(stylesMaterial)(ChangeContactInfoDialog);

export default connect(mapStateToProps, mapDispatchToProps)(styleMaterial);
