import React, { useState } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core';

import ListIcon from '@material-ui/icons/LibraryBooks';
import CalendarViewDayIcon from '@material-ui/icons/CalendarViewDay';

const useStyles = makeStyles((theme) => ({
    itemText: { 
        position: "fixed", 
        display: "flex",
        alignItems: "center",
        left: "60px",
        height: "60px",
        backgroundColor: "#499F68",
        width: "180px",
        borderRadius: "0px 10px 10px 0px",
        paddingLeft: "10px",
        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15), 1px 2px 3px rgba(0, 0, 0, 0.15)",
        color: "white"
    },
    item: {
        height: "60px"
    }
}));

const Menu = ({ history, userRoles, deleteTokenFunc, auth }) => {

    const classes = useStyles();

    const [ showAdmission, setShowAdmission ] = useState(false);
    const [ showTurns, setShowTurns ] = useState(false);

    return (
        <div style={{ backgroundColor: "#499F68", width: "60px", height: "calc(100vh - 64px)", overflow: "hidden" }}>
            <List>
                <Grid container alignItems="flex-end">
                    <Grid item xs={12}>
                        <ListItem className={classes.item} button onMouseLeave={() => setShowAdmission(false)} onMouseOver={() => setShowAdmission(true)} onClick={() => { history.push('/admissions'); }}>
                            <ListItemIcon>
                                <ListIcon style={{ color: "white" }}/>
                            </ListItemIcon>
                            { showAdmission && <ListItemText className={classes.itemText} primary="Admisiones" /> }
                        </ListItem>
                        <ListItem className={classes.item} button onMouseLeave={() => setShowTurns(false)} onMouseOver={() => setShowTurns(true)} onClick={() => { history.push('/turns'); }}>
                            <ListItemIcon>
                                <CalendarViewDayIcon style={{ color: "white" }}/>
                            </ListItemIcon>
                            { showTurns && <ListItemText className={classes.itemText} primary="Turnos" /> }
                        </ListItem>
                    </Grid>
                </Grid>
            </List>
        </div>
    );
};

export default Menu;
