import { 
    GET_MODALITYS
} from '../actions/modality';

const INITIAL_STATE = {
    isFetching: false,
    didInvalidate: false,
    items: []
};

const modalitys = (state = INITIAL_STATE, action) => {

    switch (action.type) {

        case `${GET_MODALITYS}_REJECTED`:{
            return {
                ...state,
                isFetching: false,
                didInvalidate: true
            };
        }
        case `${GET_MODALITYS}_PENDING`:{
            return {
                ...state,
                isFetching: true,
                didInvalidate: false
            };
        }

        case `${GET_MODALITYS}_FULFILLED`:{
            return {
                ...state,
                isFetching: false,
                didInvalidate: false,
                items: action.payload.modalitys
            };
        }

        default:{
            return state;
        }

    }
};

export default modalitys;
