import axios from "axios";

const genericActions = ({ modelToAction, modelToRoute, modelToState, axiosBaseURL, authToken }) => {
  const toAction = modelToAction;
  const toRoute = modelToRoute;
  const toState = modelToState;
  const toToken = authToken;
  const toAxiosBaseURL = axiosBaseURL;
  const GET_MODEL_BY_QUERY_AUX = `GET_${toAction}_BY_QUERY`;
  const GET_MODEL_BY_ID_AUX = `GET_${toAction}_BY_ID`;
  const CREATE_MODEL_AUX = `CREATE_${toAction}`;
  const UPDATE_MODEL_AUX = `UPDATE_${toAction}`;
  const DELETE_MODEL_AUX = `DELETE_${toAction}`;
  const RESET_MODEL_AUX = `RESET_${toAction}`;
  const getModelByQuery = (query) => {
    return (dispatch, getState) => {
      const state = getState();
      const route = toRoute;
      const baseURL = toAxiosBaseURL;
      const token = toToken;
      const GET_MODEL_BY_QUERY = GET_MODEL_BY_QUERY_AUX;
      return dispatch({
        type: GET_MODEL_BY_QUERY,
        payload: axios.get(`${ baseURL ? baseURL : "" }/api/${route}/find${query}`, token && { headers: { Authorization: `Bearer ${state.auth[token]}` } })
          .then((json) => {
            return {
              models: json.data,
            };
          })
      });
    }
  }
  const getModelById = (id) => {
    return (dispatch, getState) => {
      const state = getState();
      const route = toRoute;
      const baseURL = toAxiosBaseURL;
      const token = toToken;
      const GET_MODEL_BY_ID = GET_MODEL_BY_ID_AUX;
      return dispatch({
        type: GET_MODEL_BY_ID,
        payload: axios.get(`${ baseURL ? baseURL : "" }/api/${route}/${id}`, token && { headers: { Authorization: `Bearer ${state.auth[token]}` } })
          .then((json) => {
            return {
              model: json.data,
            };
          })
      });
    }
  }
  const createModel = (model) => {
    return (dispatch, getState) => {
      const state = getState();
      const route = toRoute;
      const baseURL = toAxiosBaseURL;
      const token = toToken;
      const CREATE_MODEL = CREATE_MODEL_AUX;
      return dispatch({
        type: CREATE_MODEL,
        payload: axios.post(`${ baseURL ? baseURL : "" }/api/${route}`, { ...model }, token && { headers: { Authorization: `Bearer ${state.auth[token]}` } })
          .then((json) => {
            return {
              model: json.data,
            };
          })
      });
    }
  }
  const updateModel = (model, id) => {
    return (dispatch, getState) => {
      const state = getState();
      const route = toRoute;
      const baseURL = toAxiosBaseURL;
      const token = toToken;
      const UPDATE_MODEL = UPDATE_MODEL_AUX;
      return dispatch({
        type: UPDATE_MODEL,
        payload: axios.put(`${ baseURL ? baseURL : "" }/api/${route}`, { ...model, id: id }, token && { headers: { Authorization: `Bearer ${state.auth[token]}` } })
          .then((json) => {
            return {
              model: json.data,
            };
          })
      });
    }
  }
  const deleteModel = (id) => {
    return (dispatch, getState) => {
      const state = getState();
      const route = toRoute;
      const baseURL = toAxiosBaseURL;
      const token = toToken;
      const DELETE_MODEL = DELETE_MODEL_AUX;
      return dispatch({
        type: DELETE_MODEL,
        payload: axios.delete(`${ baseURL ? baseURL : "" }/api/${route}`, { data: { id: id }}, token && { headers: { Authorization: `Bearer ${state.auth[token]}` } })
          .then((json) => {
            return {
              model: json.data,
            };
          })
      });
    }
  }
  const resetModel = () => {
    const RESET_MODEL = RESET_MODEL_AUX;
    return {
      type: RESET_MODEL_AUX
    };
  }

  const shouldFetchModels = (state) => {
    const model = toState;
    const models = state[`${model}`];
    if (!models.items.length) {
      return true;
    }
    if (models.isFetching) {
      return false;
    }
    return models.didInvalidate;
  };

  const fetchModelsIfNeeded = (query) => {
    return (dispatch, getState) => {
      if (shouldFetchModels(getState())) {
        return dispatch(getModelByQuery(query));
      }
    };
  };

  return ({
    actionTypes: {
      GET_MODEL_BY_QUERY: GET_MODEL_BY_QUERY_AUX,
      GET_MODEL_BY_ID: GET_MODEL_BY_ID_AUX,
      CREATE_MODEL: CREATE_MODEL_AUX,
      UPDATE_MODEL: UPDATE_MODEL_AUX,
      DELETE_MODEL: DELETE_MODEL_AUX,
      RESET_MODEL: RESET_MODEL_AUX
    },
    actions: {
      getModelByQuery,
      getModelById,
      createModel,
      updateModel,
      deleteModel,
      fetchModelsIfNeeded,
      resetModel
    }
  });

}

export default genericActions;
