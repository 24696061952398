import React, { useState, useEffect } from "react";
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const CustomAutocomplete = (props) => {

  const {
    valuesRender,
    variant,
    form,
    field: input,
    options,
    placeholder,
    label,
    multiple,
    resetAuto,
    clearReset,
    disabled,
    onChange } = props;

  const [ values, setValues ] = useState(multiple ? [] : null);
  const [ inOptions, setInOptions ] = useState([]);

  useEffect(() => {
    setInOptions(options)
  }, [options]);

  useEffect(() => {
    if(valuesRender){
      const toFind = multiple ? valuesRender : [valuesRender];
      const newOptions = options.filter(option => !toFind.find(value => value.value.id === option.value.id || (!value.value.id && value.value === option.value)))
      setInOptions(newOptions);
      setValues(valuesRender);
    } else {
      setInOptions(options);
      setValues(multiple ? [] : null);
    }
  }, [valuesRender]);

  useEffect(() => {
    if(resetAuto){
      clearReset();
      setValues(multiple ? [] : null);
    }
  }, [resetAuto]);

  useEffect(() => {
    if((multiple && !values.length) || !values){
      setInOptions(options);
    }
  }, [values]);

  const handleChange = (value) => {
    if(value){
      setValues(value);
      const aux = multiple ? value.map(v => v.value) : value.value;
      return form.setFieldValue(input.name, aux);
    } else {
      setValues(multiple ? [] : null);
      return form.setFieldValue(input.name, null);
    }
  }

  return (
    <Autocomplete
      id={`simple-autocomplete-${input.name}`}
      multiple={multiple || false}
      disabled={disabled}
      value={values}
      options={inOptions}
      onChange={(event, value, reason) => {
        if(onChange){
          onChange(value);
        }
        return handleChange(value)
      }}
      disableCloseOnSelect={multiple}
      noOptionsText="Sin resultados..."
      ChipProps={{ size: "small" }}
      filterSelectedOptions
      getOptionLabel={(option) => option.label}
      renderOption={(option, state) => {
        return (<React.Fragment>{option.label}</React.Fragment>);
      }}
      renderInput={(params) => (
        <TextField {...params} variant={variant} label={ label } placeholder={ placeholder } />
      )}
    />
  );
};

export default CustomAutocomplete;
