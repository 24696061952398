import React, { useState } from 'react';
import { Field, useFormik, FormikProvider } from 'formik';
import { bindActionCreators } from 'redux';
import { connect as connectRedux } from 'react-redux';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import SaveIcon from '@material-ui/icons/Save';

import CustomDialog from "./CustomDialog";
import CustomTextField from "./form/CustomTextField";

import userActions from "../../actions/user";
import { setSnack } from "../../actions/globals";

const ChangePasswordDialog = (props) => {

  const { open, handleModalClose } = props;

  const handleChangePasswordSubmit = (values, actions) => {
    const data = {
      password: values.password,
      userName: props.userName
    };
    props.changePassword(data).then(handleChangeDone);
  }

  const formik = useFormik({
     initialValues: {
       password: '',
       repassword: ''
     },
     onSubmit: handleChangePasswordSubmit,
   });

  const handleChangeDone = () => {
    props.handleModalClose();
    props.setSnack({
      open: true,
      message: "Se cambió correctamente la contraseña",
      title: "Cambio de contraseña",
      severity: "success"
    });
  }

  const title = <Typography variant="h5">
                  { "Cambio de Contraseña" }
                </Typography>;

  const actions = <FormikProvider value={formik}>
                    <Button
                      onClick={() => {
                        formik.resetForm();
                        handleModalClose();
                      }}
                      style={{ width: 150, height: 43 }}
                    >
                      Cancelar
                    </Button>
                    <Button
                      variant="outlined"
                      style={{ marginLeft: 15, width: 150 }}
                      color="primary"
                      disabled={(props.userLoading || props.invalid)}
                      onClick={formik.handleSubmit}
                    >
                      {props.userLoading ? <CircularProgress style={{ marginRight: 8 }} size={14} /> : <SaveIcon style={{ marginRight: 8 }} />}
                      Guardar
                    </Button>
                  </FormikProvider>;

  const content = <FormikProvider value={formik}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Field
                          name="password"
                          component={CustomTextField}
                          label="Nueva Contraseña"
                          type="password"
                          showError
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name="repassword"
                          component={CustomTextField}
                          label="Reescriba Contraseña"
                          type="password"
                          showError
                        />
                      </Grid>
                    </Grid>
                  </FormikProvider>

  return (
    <CustomDialog
      handleClose={handleModalClose}
      handleCloseParams={[]}
      open={open}
      title={title}
      actions={actions}
      content={content}
      maxWidth="sm"
      fullWidth
    />
  );
}

const mapDispatchToProps = (dispatch) => {
  const { changePassword } = userActions.actions;
  return bindActionCreators({
    changePassword,
    setSnack
  }, dispatch);
};

const mapStateToProps = (state) => {
  return {
    userLoading: state.users.isFetching
  };
};

export default connectRedux(mapStateToProps, mapDispatchToProps)(ChangePasswordDialog);
