import agendaTypeActions from "../actions/agendaType";
import genericReducer from "./generic.reducer";

const INITIAL_STATE = {
  isFetching: false,
  didInvalidate: false,
  items: [],
  item: null,
  error: null
};

export default genericReducer({
  actions: agendaTypeActions,
  INITIAL_STATE,
  customReducer: null
});