import {
  DELETE_TOKEN,
  SIGN_IN,
  REQUEST_PASSWORD_RESET,
  RESET_PASSWORD,
  VERIFY_TOKEN,
  VERIFY_MANAGEMENT_TOKEN,
  VALIDATE_TOKEN,
  RESET_AUTH_FETCHING,
  CONFIRM_EMAIL_TOKEN
} from '../actions/auth';

import userActions from '../actions/user';

const { CHANGE_CONTACT_INFO } = userActions.actionTypes;

const INITIAL_STATE = {
  isFetching: false,
  didInvalidate: false,
  resetPass: {},
  id: null,
  appointmentToken: null,
  emrToken: null,
  managementToken: null,
  risToken: null,
  userName: null,
  email: null,
  roles: [],
  defaultBranchOffice: null,
  branchOffices: [],
  requireChangePassword: false,
  verified: false,
  error: null
};

const user = (state = INITIAL_STATE, action) => {
  switch (action.type) {

  // Verifiy registration email token
  case `${CONFIRM_EMAIL_TOKEN}_PENDING`: {
    return {
      ...state,
      isFetching: true,
      didInvalidate: false
    };
  }

  case `${CONFIRM_EMAIL_TOKEN}_REJECTED`: {
    return {
      ...state,
      isFetching: false,
      didInvalidate: true,
      verified: false,
      error: action.payload.error
    };
  }

  case `${CONFIRM_EMAIL_TOKEN}_FULFILLED`: {
    return {
      ...state,
      isFetching: false,
      didInvalidate: false,
      verified: action.payload.data,
      error: null
    };
  }

  // Verifiy external auth

  case `${VERIFY_MANAGEMENT_TOKEN}_PENDING`: {
    return {
      ...state,
      isFetching: true,
      didInvalidate: false
    };
  }

  case `${VERIFY_MANAGEMENT_TOKEN}_REJECTED`: {
    return {
      ...state,
      isFetching: false,
      didInvalidate: true,
      _id: null,
      token: null,
      emrToken: null,
      managementToken: null,
      risToken: null,
      username: null,
      email: null,
      roles: [],
      defaultBranchOffice: null,
      branchOffices: [],
      requireChangePassword: false,
      error: action.payload.error
    };
  }

  case `${VERIFY_MANAGEMENT_TOKEN}_FULFILLED`: {

    const { user: userData, jwt, emrJwt, risJwt, managementJwt } = action.payload.data;
    const roles = {};
    let rolesArr = userData.newRoles;
    rolesArr.map((rol) => {
      return (roles[rol.slug] = true);
    });

    return {
      ...state,
      isFetching: false,
      didInvalidate: false,
      id: userData.id,
      token: jwt,
      emrToken: emrJwt,
      managementToken: managementJwt,
      risToken: risJwt,
      userName: userData.userName,
      email: userData.email,
      roles: roles,
      defaultBranchOffice: userData.defaultBranchOffice,
      branchOffices: userData.branchOffices,
      requireChangePassword: userData.requireChangePassword,
      error: null
    };
  }

  // Reset password
  case `${RESET_PASSWORD}_PENDING`: {
    return {
      ...state,
      isFetching: true,
      didInvalidate: false
    };
  }

  case `${RESET_PASSWORD}_REJECTED`: {
    return {
      ...state,
      isFetching: false,
      didInvalidate: true,
      verified: false,
      error: action.payload.error
    };
  }

  case `${RESET_PASSWORD}_FULFILLED`: {
    return {
      ...state,
      isFetching: false,
      didInvalidate: false,
      verified: action.payload.data,
      error: null
    };
  }

  // Verifiy token
  case `${VERIFY_TOKEN}_PENDING`: {
    return {
      ...state,
      isFetching: true,
      didInvalidate: false
    };
  }

  case `${VERIFY_TOKEN}_REJECTED`: {
    return {
      ...state,
      isFetching: false,
      didInvalidate: true,
      verified: false,
      error: action.payload.error
    };
  }

  case `${VERIFY_TOKEN}_FULFILLED`: {
    return {
      ...state,
      isFetching: false,
      didInvalidate: false,
      verified: action.payload.data,
      error: null
    };
  }

  // Request password reset reducers

  case `${REQUEST_PASSWORD_RESET}_PENDING`: {
    return {
      ...state,
      isFetching: true,
      didInvalidate: false
    };
  }

  case `${REQUEST_PASSWORD_RESET}_REJECTED`: {
    return {
      ...state,
      isFetching: false,
      didInvalidate: true,
      resetPass: {},
      error: action.payload.error
    };
  }

  case `${REQUEST_PASSWORD_RESET}_FULFILLED`: {
    return {
      ...state,
      isFetching: false,
      didInvalidate: false,
      resetPass: action.payload.data,
      error: null
    };
  }

  // Signin reducers

  case `${SIGN_IN}_PENDING`: {
    return {
      ...state,
      isFetching: true,
      didInvalidate: false
    };
  }

  case `${SIGN_IN}_REJECTED`: {
    return {
      ...state,
      isFetching: false,
      didInvalidate: true,
      _id: null,
      token: null,
      emrToken: null,
      risToken: null,
      managementToken: null,
      username: null,
      email: null,
      roles: [],
      defaultBranchOffice: null,
      branchOffices: [],
      requireChangePassword: false,
      error: action.payload.error
    };
  }

  case `${SIGN_IN}_FULFILLED`: {

    const { user: userData, jwt, emrJwt, managementJwt, appointmentJwt } = action.payload.data;
    
    const roles = {};
    let rolesArr = userData.roles;
    rolesArr.map((rol) => {
      return (roles[rol.slug] = true);
    });

    return {
      ...state,
      isFetching: false,
      didInvalidate: false,
      id: userData.id,
      appointmentToken: appointmentJwt,
      emrToken: emrJwt,
      managementToken: managementJwt,
      risToken: jwt,
      userName: userData.userName,
      email: userData.email,
      roles: roles,
      defaultBranchOffice: userData.defaultBranchOffice,
      branchOffices: userData.branchOffices,
      requireChangePassword: userData.requireChangePassword,
      error: null
    };
  }

  case `${CHANGE_CONTACT_INFO}_PENDING`: {
    return {
      ...state,
      isFetching: true,
      didInvalidate: false
    };
  }

  case `${CHANGE_CONTACT_INFO}_REJECTED`: {
    return {
      ...state,
      isFetching: false,
      didInvalidate: true,
      error: action.payload.error
    };
  }

  case `${CHANGE_CONTACT_INFO}_FULFILLED`: {
    const { user: userData } = action.payload;
    return {
      ...state,
      isFetching: false,
      didInvalidate: false,
      email: userData.email,
      error: null
    };
  }

  /*case `${DELETE_TOKEN}`: {
    return {
      ...INITIAL_STATE
    };
  }*/

  case `${RESET_AUTH_FETCHING}`: {
    return {
      ...state,
      isFetching: false,
      didInvalidate: false
    };
  }

  default:
    return state;
  }
};

export default user;
