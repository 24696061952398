import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import ContentAdd from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import { makeStyles, Typography } from '@material-ui/core';

import { 
    getAdmissionsFilter
} from '../../../actions/admission';

import {
  fetchBranchOfficesIfNeeded
} from '../../../actions/branchOffice';

import Admission from './Admission';
import FilterAdmissions from './FilterAdmissions';
import MoreButton from '../../commons/MoreButton';

const useStyles = makeStyles((theme) => ({
  row: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  }
}));

const AdmissionList = (props) => {

  const classes = useStyles();

  useEffect(() => {
    props.fetchBranchOfficesIfNeeded();
    let query = '?reportState=retire,delivered&sort=-entryDatetime';
    props.getAdmissionsFilter(query, 0, 5);
  }, []);

  const {
    admissions,
    branchOffices
  } = props;

  return (
    <div className={classes.row} >
      { false && <FilterAdmissions /> }
      { admissions.items && admissions.items.length > 0  ?
        admissions.items.map(((admission, i) => {

          let location;
          branchOffices.allBranchOffices.map((branch) => {
            if (branch.id === admission.locationId) {
              location = branch.Name
            };
          });

          return (
            <Admission
              key={admission.id}
              admission={admission}
              isFetching={admissions.isFetching}
              location={location}
            />
          );
        }))
        :
        <div style={{ display: 'flex', alignItems: "center",  justifyContent: 'center', minHeight: "80vh" }}>
          { 
            admissions.isFetching ?
              <div style={{ width: '100%', display: 'flex', flexDirection: "column", alignItems: "center", justifyContent: 'center' }}>
                <CircularProgress />
                <Typography style={{ marginTop: "20px" }}>Cargando información de admisiones...</Typography>
              </div> : 
              <Chip
                label="No hay resultados"
                color="primary"
              />
          }
        </div>
      }

      {admissions.items && admissions.items.length < admissions.count &&
        <div>
        { 
          admissions.isFetching ?
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </div>
          :
          <MoreButton getItems={props.getAdmissionsFilter} skip={admissions.items.length} limit={3} query={admissions.lastQuery} />
        }
        </div>
      }
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    admissions: state.admissions,
    userRoles: state.auth.roles,
    userID: state.auth.userID,
    branchOffices: state.branchOffices
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getAdmissionsFilter,
    fetchBranchOfficesIfNeeded
  }, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(AdmissionList);
