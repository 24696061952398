import genericActions from "./generic.actions";

const actions = genericActions({
  modelToAction: "RESOURCE",
  modelToRoute: "resource",
  modelToState: "resources",
  axiosBaseURL: process.env.REACT_APP_MEDEXWARE_APPOINTMENT_URL,
  authToken: "appointmentToken"
});

export default actions;
