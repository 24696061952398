import { 
    GET_SERVICES
} from '../actions/service';

const INITIAL_STATE = {
isFetching: false,
didInvalidate: false,
items: [],
itemId: {},
};

const services = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case `${GET_SERVICES}_REJECTED`:{
            return {
                ...state,
                isFetching: false,
                didInvalidate: true
            };
        }
        case `${GET_SERVICES}_PENDING`:{
            return {
                ...state,
                isFetching: true,
                didInvalidate: false
            };
        }
        case `${GET_SERVICES}_FULFILLED`:{
            return {
                ...state,
                isFetching: false,
                didInvalidate: false,
                items: action.payload.services,
                lastUpdated: action.payload.receivedAt
            };
        }

        default: {
            return state;
        }
    }
};

export default services;
