import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';

import { isMobile } from "react-device-detect";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: isMobile ? theme.spacing(3) : theme.spacing(4),
      	margin: 0,
		color: "red"
	}
}));

const ErrorShow = (props) => {

	const { type } = props;

	const classes = useStyles();

	let text;

	if(type === 1) text = "No se encontró ningún registro que coincida con estos datos, por favor verifique";
	if(type === 3) text = "Error de conexión, por favor intente nuevamente";

	return 	<Typography className={classes.root} variant="body1" gutterBottom align="left">
	       		{ text }
	        </Typography>

}

export default ErrorShow;

