import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { isMobile, withOrientationChange } from "react-device-detect";

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import DescriptionIcon from '@material-ui/icons/Description';

import { generateAdmissionPDF, verifyStudyInstanceUID } from '../../../actions/admission';
import CustomSnackBar from '../../commons/CustomSnackBar';

const styles = (theme) => {
  return {
    button: {
      height: 35,
      margin: theme.spacing(1)
    },
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      marginBottom: theme.spacing(-1)
    },
  };
};

class Admission extends Component {

  constructor(props) {
    super(props);

    this.state = {
      timeElapsed: '',
      genModal: {
        open: false,
        actionIfConfirm: null,
        params: [],
        actionContent: "",
        actionTitle: ""
      },
      modalSend: {
        open: false,
        admission: null,
        handleClose: null
      },
      solicite: null,
      snack: {
        open: false,
        severity: "error",
        message: ""
      }
    };
  }

  componentDidMount() {
    this.timeElapsed();
  }

  openPdf = (gcUri) => {
    window.open(gcUri);
  }

  callPDFExport = () => {
    this.props.generateAdmissionPDF(this.props.admission.id, true).then((response) => {
        this.openPdf(response.value.file);
    });
  }

  timeElapsed = () => {
    const { admission } = this.props;
    const localTime = moment.utc(admission.entryDatetime, 'YYYY/MM/DD HH:mm:ss').local();
    const duration = moment.duration(moment().diff(localTime));

    let timeElapsed;

    if(duration.asDays() <= 90){
      timeElapsed = `${Math.floor(duration.asDays())} d ${Math.floor(duration.hours())} h ${Math.floor(duration.minutes())} m`;
    }

    if(duration.asDays() > 90 && duration.asDays() <= 365){
      timeElapsed = `${Math.floor(duration.asMonths())} meses`;
    }

    if(duration.asDays() > 365){
      timeElapsed = `${Math.floor(duration.asYears())} años ${Math.floor(duration.months())} meses`;
    }

    this.setState({
      timeElapsed: timeElapsed
    });
  }

  handleSendEmail = (admission) => {
    this.setState({ modalSend: { open: true, admission: admission, handleClose: this.handleModalSendClose } });
  }

  handleModalSendClose = () => {
    this.setState({ modalSend: { open: false } });
  }

  handlePACSViewer = (admissionId, uids) => {
    this.props.verifyStudyInstanceUID({ admissionId, studyInstanceUids: uids }).then((resp) => {
      if(resp.value.data && resp.value.data.status === "online"){
        window.open(`${process.env.REACT_APP_VIEWPORT_CONFIG}/${this.props.admission.studyInstanceUids}`);
      } else {
        this.setState({
          snack: {
            open: true,
            severity: "warning",
            message: "EL SISTEMA ESTÁ RECUPERANDO EL ESTUDIO SOLICITADO. POR FAVOR INTENTE EN UNOS MINUTOS"
          }
        });
      }
    });
  }

  handleSnackClose = () => {
    this.setState({
      snack: {
        open: false,
        severity: "success",
        message: ""
      }
    });
  }

  render() {
    const {
      classes,
      admission
    } = this.props;

    const reportStateView = () => {
      const { reportState } = admission;
      if (reportState === 'derivative') return 'DERIVADO';
      if (reportState === 'inProcess') return 'EN PROCESO';
      if (reportState === 'signed') return 'FIRMADO';
      if (reportState === 'accomplished') return 'REALIZADO';
      if (reportState === 'retire') return 'PARA RETIRAR';
      if (reportState === 'finished') return 'FINALIZADO';
      if (reportState === 'cancelled') return 'CANCELADO';
      return 'ADMITIDO';
    };

    return (
      <React.Fragment>
        <CustomSnackBar 
          severity={ this.state.snack.severity }
          open={ this.state.snack.open }
          message={ this.state.snack.message }
          setExternalOpen={ this.handleSnackClose }
        />
        <Paper style={{ marginBottom: 15, boxShadow: '0 2px 6px 0 hsla(0,0%,0%,0.2)' }}>
          <Grid>
            <Grid className={classes.root} elevation={1}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Typography variant="body1" style={{ fontWeight: 'bold' }} gutterBottom align="left">
                    Nro. Informe: { admission.isSubadmission ? `${admission.admissionId ? admission.admissionId : admission.externalAdmissionId}/${admission.admissionIndex}` : admission.admissionId ? admission.admissionId : admission.externalAdmissionId }
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography variant="body1" style={{ fontWeight: 'bold' }} gutterBottom align="left">
                    Fecha: {moment.utc(admission.entryDatetime).local().format('DD/MM/YYYY - HH:mm')}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography variant="body1" style={{ fontWeight: 'bold' }} gutterBottom align="left">
                    Tiempo transcurrido: {this.state.timeElapsed}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Typography variant="body1" gutterBottom align="left">
                    Documento: {`${admission.documentType ? `${admission.documentType} ` : ""}${admission.documentNumber}`}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography variant="body1" gutterBottom align="left">
                    Paciente: {admission.requestor.toUpperCase()}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography variant="body1" gutterBottom align="left">
                    Sede: {this.props.location}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Typography variant="body1" gutterBottom align="left">
                    Tipo de Ingreso: {admission.entryType.toUpperCase()}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography variant="body1" gutterBottom align="left">
                    Solicitante: {admission.soliciteDenomination && admission.soliciteDenomination.toUpperCase()}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography variant="body1" gutterBottom align="left">
                    Recurso: {admission.responsibleProfessional.toUpperCase()}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Typography variant="body1" gutterBottom align="left" style={{ fontWeight: 'bold' }}>
                    Tipo de Estudio: { admission.examType }
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography variant="body1" gutterBottom align="left" style={{ fontWeight: 'bold' }}>
                    Estado: { reportStateView() }
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography variant="body1" gutterBottom align="left">
                    Modalidad: { admission.modality }
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container style={{ borderTop: 'none', background: '#f6f6f6', height: isMobile ? (this.props.isLandscape ? 50 : 100) : 50, paddingTop: isMobile ? 10 : 0 }}>
              <Grid item xs={12} align="right">
                { isMobile ?
                  <IconButton
                    title="VER INFORME"
                    color="secondary"
                    onClick={this.callPDFExport}
                    style={{marginRight: -5}}
                    disabled={!admission.reportState}
                  >
                    <DescriptionIcon style={{ fontSize: 20, marginRight: 3 }} />
                  </IconButton> :
                  <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    //onClick={this.callPDFExport}
                    onClick={()=>window.open(admission.reportResultURL)}
                    disabled={!admission.reportState}
                  >
                    {/* { this.props.isFetching ? <CircularProgress size={16} style={{ marginRight: 3 }} /> : <DescriptionIcon style={{ fontSize: 20, marginRight: 3 }} /> } */}
                    { !isMobile && "VER INFORME" }
                  </Button>
                }
                {  isMobile ?
                    <IconButton
                      title="Ver estudio"
                      color="primary"
                      disabled={ Boolean(admission.studyInstanceUids == "blank" || admission.studyInstanceUids == null) }
                      onClick={ () => this.handlePACSViewer(admission.id, admission.studyInstanceUids) }
                      style={{marginRight: -5}}
                    >
                      <AspectRatioIcon />
                    </IconButton> :
                    <Button
                      variant="outlined"
                      size="small"
                      color="primary"
                      className={classes.button}
                      disabled={ Boolean(admission.studyInstanceUids == "blank" || admission.studyInstanceUids == null) }
                      onClick={ () => this.handlePACSViewer(admission.id, admission.studyInstanceUids) }
                    >
                      <AspectRatioIcon style={{ marginRight: 3 }} /> { !isMobile && "Ver estudio" }
                    </Button>
                }
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    admissions: state.admissions
  };
};


const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    generateAdmissionPDF,
    verifyStudyInstanceUID
  }, dispatch);
};

const orientationChanged = withOrientationChange(Admission)
const componentWithStyles = withStyles(styles, { withTheme: true })(orientationChanged);
export default connect(mapStateToProps, mapDispatchToProps)(componentWithStyles);
