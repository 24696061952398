import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { isMobile, withOrientationChange } from "react-device-detect";

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import DescriptionIcon from '@material-ui/icons/Description';

import { generateAdmissionPDF, verifyStudyInstanceUID } from '../../../actions/admission';
import CustomSnackBar from '../../commons/CustomSnackBar';

const styles = (theme) => {
  return {
    button: {
      height: 35,
      margin: theme.spacing(1)
    },
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      marginBottom: theme.spacing(-1)
    },
  };
};

class Admission extends Component {

  constructor(props) {
    super(props);

    this.state = {
      timeElapsed: '',
      genModal: {
        open: false,
        actionIfConfirm: null,
        params: [],
        actionContent: "",
        actionTitle: ""
      },
      modalSend: {
        open: false,
        admission: null,
        handleClose: null
      },
      solicite: null,
      snack: {
        open: false,
        severity: "error",
        message: ""
      },
      showCancel: false
    };
  }

  componentDidMount() {
    const startDatetime = moment.utc(this.props.appointment.startDatetime, "YYYY-MM-DD HH:mm:ss").local();
    const endDatetime = moment.utc(this.props.appointment.endDatetime, "YYYY-MM-DD HH:mm:ss").local();
    const now = moment();
    const showCancel = endDatetime > now && now < startDatetime.subtract(3, "days") && this.props.appointment.appointmentState.key !== "cancelled";
    this.setState({ showCancel });
  }

  openPdf = (gcUri) => {
    window.open(gcUri);
  }

  callPDFExport = () => {
    this.props.generateAdmissionPDF(this.props.admission.id, true).then((response) => {
        this.openPdf(response.value.file);
    });
  }

  handleSendEmail = (admission) => {
    this.setState({ modalSend: { open: true, admission: admission, handleClose: this.handleModalSendClose } });
  }

  handleModalSendClose = () => {
    this.setState({ modalSend: { open: false } });
  }

  handlePACSViewer = (admissionId, uids) => {
    this.props.verifyStudyInstanceUID({ admissionId, studyInstanceUids: uids }).then((resp) => {
      if(resp.value.data && resp.value.data.status === "online"){
        window.open(`${process.env.REACT_APP_VIEWPORT_CONFIG}/${this.props.admission.studyInstanceUids}`);
      } else {
        this.setState({
          snack: {
            open: true,
            severity: "warning",
            message: "EL SISTEMA ESTÁ RECUPERANDO EL ESTUDIO SOLICITADO. POR FAVOR INTENTE EN UNOS MINUTOS"
          }
        });
      }
    });
  }

  handleSnackClose = () => {
    this.setState({
      snack: {
        open: false,
        severity: "success",
        message: ""
      }
    });
  }

  render() {
    const {
      classes,
      appointment
    } = this.props;

    return (
      <React.Fragment>
        <CustomSnackBar 
          severity={ this.state.snack.severity }
          open={ this.state.snack.open }
          message={ this.state.snack.message }
          setExternalOpen={ this.handleSnackClose }
        />
        <Paper style={{ marginBottom: 15, boxShadow: '0 2px 6px 0 hsla(0,0%,0%,0.2)' }}>
          <Grid>
            <Grid className={classes.root} elevation={1}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Typography variant="body1" style={{ fontWeight: 'bold' }} gutterBottom align="left">
                    Fecha de inicio: { appointment.startDateF }
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography variant="body1" style={{ fontWeight: 'bold' }} gutterBottom align="left">
                    Hora de inicio: { appointment.startTimeF }
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography variant="body1" style={{ fontWeight: 'bold' }} gutterBottom align="left">
                    Duración: { appointment.durationF }
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Typography variant="body1" gutterBottom align="left">
                    Estado: { appointment.agendaTurnStateF }
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography variant="body1" gutterBottom align="left">
                    Recurso: { appointment.resourceNameF }
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container style={{ borderTop: 'none', background: '#f6f6f6', height: isMobile ? (this.props.isLandscape ? 50 : 100) : 50, paddingTop: isMobile ? 10 : 0 }}>
              <Grid item xs={12} align="right">
                {   this.state.showCancel && 
                    (isMobile ?
                    <IconButton
                        title="CANCELAR"
                        color="secondary"
                        onClick={() => this.props.handleCancelAppointment(appointment)}
                        style={{marginRight: -5}}
                    >
                        <DescriptionIcon style={{ fontSize: 20, marginRight: 3 }} />
                    </IconButton> :
                    <Button
                        size="small"
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        onClick={() => this.props.handleCancelAppointment(appointment)}
                    >
                        { this.props.isFetching ? <CircularProgress size={16} style={{ marginRight: 3 }} /> : <DescriptionIcon style={{ fontSize: 20, marginRight: 3 }} /> }
                        { !isMobile && "CANCELAR" }
                    </Button>)
                }
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    admissions: state.admissions
  };
};


const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    generateAdmissionPDF,
    verifyStudyInstanceUID
  }, dispatch);
};

const orientationChanged = withOrientationChange(Admission)
const componentWithStyles = withStyles(styles, { withTheme: true })(orientationChanged);
export default connect(mapStateToProps, mapDispatchToProps)(componentWithStyles);
