import React, { useEffect, useRef, useState } from "react";

import axios from "axios";

import { 
    makeStyles, 
    Grid,
    Typography,
    TextField,
    Button,
    CircularProgress,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    OutlinedInput,
    InputAdornment,
    IconButton
} from "@material-ui/core";

import {
    Pageview,
    ExitToApp,
    Visibility as VisibilityIcon
} from "@material-ui/icons";

import { isMobile } from "react-device-detect";

import { Link } from "react-router-dom";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    root: {
        flex: true,
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(3),
        minHeight: "90vh"
    },
    link: {
        textDecoration: "none",
        fontWeight: "600",
        color: "black"
    },
    links: {
        textAlign: "center"
    },
    extendedIcon: {
        marginRight: theme.spacing(2)
    },
    title: {
        marginTop: theme.spacing(3)
    }
}));

let passwordValidator = require('password-validator');

const SignupLayout = (props) => {

    const validatePassword = (password) => {
    
        let schema = new passwordValidator();
    
        // Add properties to it
        schema
        .is().min(8)                                    // Minimum length 8
        .has().uppercase()                              // Must have uppercase letters
        .has().digits();                                // Must have digits
    
        let errorSchema = schema.validate(password, { list: true });
    
        let errorv = []
    
        errorSchema.map((ev) => {
            if(ev == "min") {
                errorv.push("El password debe ser de al menos 8 caracteres");
            }
            if(ev == "uppercase") {
                errorv.push("El password debe contener al menos una letra mayúscula");
            }
            if(ev == "digits") {
                errorv.push("El password debe contener al menos 2 dígitos");
            }
        });
    
        return errorv;
    
    }

    const classes = useStyles();

	const [ errors, setErrors ] = useState({ 
        name: "El campo es requerido", 
        lastname: "El campo es requerido", 
        documentType: "El campo es requerido", 
        documentNumber: "El campo es requerido", 
        email: "El campo es requerido", 
        password: "El campo es requerido",
        passwordConfirmation: "El campo es requerido",
        dateOfBirth: "El campo es requerido",
        gender: "El campo es requerido"
    });

    const [ touched, setTouched ] = useState(false);
    
    const [ documentType, setDocumentType ] = useState("");
    const [ gender, setGender ] = useState("");
	const [ errorMessage, setErrorMessage ] = useState("");
	const [ message, setMessage ] = useState("");
    const [ passwordType, setPasswordType ] = useState("password");
    const [ passwordTypeConfirmation, setPasswordTypeConfirmation ] = useState("password");

    const [ fetching, setFetching ] = useState(false);

	const inputName = useRef(null);
	const inputLastname = useRef(null);
    const inputDocumentNumber = useRef(null);
    const inputEmail = useRef(null);
    const inputPassword = useRef(null);
    const inputPasswordConfirmation = useRef(null);
    const inputDateOfBirth = useRef(null);

    const handleSignUp = async (values) => {
        
        try {
        
            let invalid = false

            Object.keys(errors).map(key => {
                if(errors[key]){
                    invalid = true;
                }
            })

            if(invalid){

                setTouched(true);
            
            } else {

                setFetching(true);
                setErrorMessage("");

                const rolesResponse = await axios.get(`${process.env.REACT_APP_MEDEXWARE_RIS_URL}/api/role/slug/patient`);
                const branchOfficesResponse = await axios.get(`${process.env.REACT_APP_MEDEXWARE_RIS_URL}/api/branchOffice/allmethods?`);

                const model = { 
                    ...values, 
                    userName: values.email, 
                    startDate: moment.utc(),
                    newRoles: [ rolesResponse.data._id ],
                    state: "INACTIVO",
                    branchOffices: branchOfficesResponse.data.map(branch => branch.id),
                    defaultBranchOffice: branchOfficesResponse.data[0].id,
                    fromPortal: true
                }

                const response = await axios.post(`${process.env.REACT_APP_MEDEXWARE_RIS_URL}/signUp`, model);

                if(response.data.success === false){
                    setErrorMessage(response.data.message);
                } else {
                    props.history.push({ pathname: "/signin", state: { from: "signup", message: "Le hemos enviado un correo electrónico para culminar el proceso de registro" } });
                }

                setFetching(false);
            }
            
        } catch (error) {
            setFetching(false);
            setErrorMessage("Ocurrió un error a nivel del sistema, por favor contacte al administrador");
        }
    }

    const handleDocTypeChange = (event) => {
        if(event.target.value) {
            setDocumentType(event.target.value);
            setErrors({ ...errors, documentType: "" });
        } else {
            setErrors({ ...errors, documentType: "El campo es requerido" });
        }
    }

    const handleGenderChange = (event) => {
        if(event.target.value) {
            setGender(event.target.value);
            setErrors({ ...errors, gender: "" });
        } else {
            setErrors({ ...errors, gender: "El campo es requerido" });
        }
    }

    const handleChange = (value, field) => {

        let newErrors = { ...errors };

        if(field === "password"){
            if(value){
                let fails = validatePassword(value);
                if(fails.length > 0) {
                    newErrors[field] = fails[0];
                } else {
                    if(value === inputPasswordConfirmation.current.value){
                        newErrors[field] = "";
                    } else {
                        newErrors[field] = "";
                        newErrors["passwordConfirmation"] = "El campo confirmar contraseña debe ser igual al campo contraseña";
                    }
                }
            } else {
                newErrors[field] = "El campo es requerido";
            }
        } else if (field === "passwordConfirmation") {
            if(value){
                if(value === inputPassword.current.value){
                    newErrors[field] = "";
                } else {
                    newErrors[field] = "El campo confirmar contraseña debe ser igual al campo contraseña";
                }
            } else {
                newErrors[field] = "El campo es requerido";
            }
        } else {
            newErrors[field] = value ? "" : "El campo es requerido";
        }

        setErrors(newErrors);
        
    }

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12} sm={10} md={10} lg={6} >
                <Grid container spacing={3}>
                    <Grid item xs={12} align="center">
                        <img width={isMobile? 128 : 192 } height={isMobile ? 37 : 56 }  src={`${process.env.REACT_APP_STATIC_IMAGES_URL}/logoCliente.png`} />
                    </Grid>
                    <Grid item xs={12} align="center">
                        <Typography variant="h6" style={{ fontWeight: 'bold'}}>
                            Solicitar registro
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField 
                            fullWidth
                            error={touched && errors.name}
                            inputRef={inputName}
                            autoFocus={true}
                            id={"name"}
                            label="Nombre"
                            variant="outlined"
                            helperText={ touched && errors.name } 
                            onChange={ (event) => handleChange(event.target.value, "name") }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField 
                            fullWidth
                            error={touched && errors.lastname}
                            inputRef={inputLastname}
                            id={"lastname"}
                            label="Apellido"
                            variant="outlined"
                            helperText={ touched && errors.lastname } 
                            onChange={ (event) => handleChange(event.target.value, "lastname") }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div style={{ display: "flex" }}>
                            <div style={{ width: "20%", marginRight: "30px" }}>
                                <FormControl variant="outlined" error={touched && errors.documentType}>
                                    <InputLabel id="tipo-documento-label">Tipo</InputLabel>
                                    <Select
                                        labelId="tipo-documento-label"
                                        id="id-tipo-documento"
                                        value={documentType}
                                        onChange={handleDocTypeChange}
                                        label="Tipo"
                                        style={{ width: "90px" }}
                                    >
                                        <MenuItem value="DNI">DNI</MenuItem>
                                        <MenuItem value="PAS">PAS</MenuItem>
                                    </Select>
                                    <FormHelperText>{ touched && errors.documentType }</FormHelperText>
                                </FormControl>
                            </div>
                            <div style={{ width: "80%" }}>
                                <TextField 
                                    fullWidth
                                    error={touched && errors.documentNumber}
                                    inputRef={inputDocumentNumber}
                                    id={"documentNumber"}
                                    label="Nº Documento"
                                    variant="outlined"
                                    helperText={touched && errors.documentNumber} 
                                    onChange={ (event) => handleChange(event.target.value, "documentNumber") }
                                />
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={8} md={4}>
                        <TextField 
                            fullWidth
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            error={touched && errors.dateOfBirth}
                            inputRef={inputDateOfBirth}
                            id={"dateOfBirth"}
                            label="Fecha de nacimiento"
                            variant="outlined"
                            helperText={touched && errors.documentNumber} 
                            onChange={ (event) => handleChange(event.target.value, "dateOfBirth") }
                        />
                    </Grid>
                    <Grid item xs={4} md={2}>
                        <FormControl fullWidth variant="outlined" error={touched && errors.gender}>
                            <InputLabel id="gender-label">Sexo</InputLabel>
                            <Select
                                labelId="gender-label"
                                id="id-gender"
                                value={gender}
                                onChange={handleGenderChange}
                                label="Sexo"
                                fullWidth
                            >
                                <MenuItem value="M">M</MenuItem>
                                <MenuItem value="F">F</MenuItem>
                                <MenuItem value="O">O</MenuItem>
                            </Select>
                            <FormHelperText>{ touched && errors.gender }</FormHelperText>
                        </FormControl>    
                    </Grid>
                    <Grid item xs={12}>
                        <TextField 
                            fullWidth
                            error={touched && errors.email}
                            inputRef={inputEmail}
                            id={"email"}
                            label="Correo electrónico"
                            variant="outlined"
                            helperText={touched && errors.email} 
                            onChange={ (event) => handleChange(event.target.value, "email") }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField 
                            fullWidth
                            type={passwordType}
                            error={touched && errors.password}
                            inputRef={inputPassword}
                            id={"password"}
                            label="Contraseña"
                            variant="outlined"
                            helperText={touched && errors.password} 
                            onChange={ (event) => handleChange(event.target.value, "password") }
                            InputProps={{
                                endAdornment: 
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => {
                                                let type;
                                                if(passwordType === "password"){
                                                    type = "text";
                                                } else {
                                                    type = "password";
                                                }
                                                setPasswordType(type);
                                            }}
                                        >
                                            <VisibilityIcon/>
                                        </IconButton>
                                    </InputAdornment>
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField 
                            fullWidth
                            type={passwordTypeConfirmation}
                            error={touched && errors.passwordConfirmation}
                            inputRef={inputPasswordConfirmation}
                            id={"passwordConfirmation"}
                            label="Confirmar contraseña"
                            variant="outlined"
                            helperText={touched && errors.passwordConfirmation} 
                            onChange={ (event) => handleChange(event.target.value, "passwordConfirmation") }
                            InputProps={{
                                endAdornment: 
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => {
                                                let type;
                                                if(passwordTypeConfirmation === "password"){
                                                    type = "text";
                                                } else {
                                                    type = "password";
                                                }
                                                setPasswordTypeConfirmation(type);
                                            }}
                                        >
                                            <VisibilityIcon/>
                                        </IconButton>
                                    </InputAdornment>
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            style={{ display: "flex", justifyContent: "center" }}
                        >
                            <Grid item xs={12} md={6}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    aria-label="add"
                                    onClick={() => handleSignUp({
                                        name: inputName.current.value,
                                        lastname: inputLastname.current.value,
                                        documentType: documentType,
                                        gender: gender,
                                        documentNumber: inputDocumentNumber.current.value,
                                        dateOfBirth: inputDateOfBirth.current.value,
                                        email: inputEmail.current.value,
                                        password: inputPassword.current.value,
                                        passwordConfirmation: inputPasswordConfirmation.current.value
                                    })}
                                    disabled={fetching}
                                >
                                    { fetching ? <CircularProgress size={24} className={classes.extendedIcon} />:<ExitToApp className={classes.extendedIcon} /> }
                                    SOLICITAR
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} align="center">
                        <Typography style={{ color: errorMessage ? "red" : "green" }}>{ errorMessage || message }</Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.links}>
                        <Link className={classes.link} to="/signin"><Typography>Ya tengo una cuenta</Typography></Link>
                    </Grid>
                    <Grid item xs={12} className={classes.links}>
                        <Link className={classes.link} to="/forgotten"><Typography>Olvidé mi contraseña</Typography></Link>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default SignupLayout