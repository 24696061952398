import agendaTurnActions from "../actions/agendaTurn";
import genericReducer from "./generic.reducer";

const INITIAL_STATE = {
  isFetching: false,
  didInvalidate: false,
  items: [],
  item: null,
  error: null,
  agendaTurnStates: []
};

const customReducer = (state, action) => {
  const actionTypes = agendaTurnActions.actionTypes;
  const actionNames = Object.keys(actionTypes);
  let toReturn = null;
  for(let k in actionNames){
    if(actionNames[k] === "GET_TURNS_BY_AGENDA_QUERY" && action.type === `${actionTypes[actionNames[k]]}_PENDING`){
      toReturn = {
        ...state,
        isFetching: true,
        didInvalidate: false
      }
    } else if(actionNames[k] === "GET_TURNS_BY_AGENDA_QUERY" && action.type === `${actionTypes[actionNames[k]]}_REJECTED`){
      toReturn = {
        ...state,
        isFetching: false,
        didInvalidate: true
      }
    } else if(actionNames[k] === "GET_TURNS_BY_AGENDA_QUERY" && action.type === `${actionTypes[actionNames[k]]}_FULFILLED`){
      toReturn = {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.payload.models,
        error: null
      };
    } 
  }
  
  if(action.type === "GET_APPOINTMENT_BY_QUERY_FULFILLED"){
    const { models: appointments } = action.payload;
    let newItems = [];
    appointments.map((appointment) => {
      if(appointment.agendaTurns && appointment.agendaTurns.length){
        newItems = newItems.concat(appointment.agendaTurns);
      }
    });
    toReturn = {
      ...state,
      isFetching: false,
      didInvalidate: false,
      items: newItems,
      error: null
    };
  } else if(action.type === "UPDATE_APPOINTMENT_FULFILLED"){
    const { model: appointment } = action.payload;
    const { agendaTurns } = appointment;
    const modelsIds = agendaTurns ? agendaTurns.map(agendaTurn => agendaTurn._id) : [];
    const turnState = state.agendaTurnStates.find(agendaTurnState => agendaTurnState.key === "free");
    let newState = state.items.map(item => {
      if(item.appointment && appointment._id === item.appointment._id){
        item.appointment = null;
        item.agendaTurnState = turnState;
        item.showByGroup = true;
      }
      return item;
    });
    if(agendaTurns && agendaTurns.length){
      state.items.map((item, i) => {
        if(modelsIds.includes(item._id)){
          newState = [
            ...newState.slice(0, i),
            agendaTurns.find(model => model._id === item._id),
            ...newState.slice(i + 1)
          ];
        }
      })
    } else {
      state.items.map((item, i) => {
        if(item.appointment && item.appointment._id === appointment._id){
          newState = [
            ...newState.slice(0, i),
            { ...item,
              appointment: null,
              agendaTurnState: turnState,
              showByGroup: true
            },
            ...newState.slice(i + 1)
          ];
        }
      })
    }
    toReturn = {
      ...state,
      isFetching: false,
      didInvalidate: false,
      items: newState,
      error: null
    };
  }

  return toReturn ? toReturn : state;
}

export default genericReducer({
  actions: agendaTurnActions,
  INITIAL_STATE,
  customReducer
});
