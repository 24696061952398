import { SAVE_BRANCHOFFICE, CURRENT_OFFICE, ALL_OFFICE } from '../actions/branchOffice';

const INITIAL_STATE = {
  branchOffices: [],
  currentBranchOffice: '',
  allBranchOffices: [],
};

const branchOffice = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case `${SAVE_BRANCHOFFICE}`:
    return {
      ...state,
      branchOffices: action.payload.branchOffices,
      currentBranchOffice: action.payload.defaultBranchOffice,
    };

  case `${CURRENT_OFFICE}`:
    return {
      ...state,
      currentBranchOffice: action.payload.currentID,
    };

  case `${ALL_OFFICE}_FULFILLED`:
    return {
      ...state,
      allBranchOffices: action.payload.branchOffices,
    };
    
  default:
    return state;
  }
};

export default branchOffice;
