import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import { 
    makeStyles, 
    Grid,
    Typography,
    TextField,
    Button,
    CircularProgress
} from "@material-ui/core";

import {
    Pageview,
    ExitToApp
} from "@material-ui/icons";

import { isMobile } from "react-device-detect";

import { Link } from "react-router-dom";

import { requestPasswordReset, signIn } from "../actions/auth";
import { setSnack } from "../actions/globals";

const useStyles = makeStyles((theme) => ({
    root: {
        flex: true,
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(3),
        minHeight: "82vh"
    },
    container: {
        marginTop: theme.spacing(3)
    },
    link: {
        textDecoration: "none",
        fontWeight: "600",
        color: "black"
    },
    links: {
        textAlign: "center"
    },
    extendedIcon: {
        marginRight: theme.spacing(2)
    }
}));

const ForgottenLayout = (props) => {

    const classes = useStyles();

    const dispatch = useDispatch();

    const fetching = useSelector(state => state.auth.isFetching);

	const [ emailRequired, setEmailRequired ] = useState(false);
	const [ errorMessage, setErrorMessage ] = useState("");
	const [ message, setMessage ] = useState("");

    const [ touched, setTouched ] = useState(false);

	const inputEmail = useRef(null);

    useEffect(() => {
        if(props.location.state && props.location.state.from){
            const { from, message: messageState, error: errorState } = props.location.state;
            if(from === "reset" && errorState){
                setErrorMessage(errorState);
            }
        }
    }, []);

    const handleResetPassword = async (values) => {
        try {

            const { email } = values;

            if(!email){
                setTouched(true);
            } else {
                setErrorMessage("");
                const resp = await dispatch(requestPasswordReset({ userName: email, requestFrom: "PORTAL" }));
                if(resp.value.data.error === "User with this username doesn't exists"){
                    setErrorMessage("No existe un usuario con ese email registrado en nuestro sistema");
                } else {
                    props.history.push({ pathname: "/", state: { from: "forgot", message: "Hemos enviado un correo con los pasos para reestablecer su contraseña" } });
                }
            }

        } catch (error) {
            console.log("Error:", error);
        }

    }

    const handleChange = (value, field) => {
        if(!value){
            if(field === "email"){
                setEmailRequired(true);
            }
        }
    }

    return (
        <Grid container className={classes.root}>
            <Grid item xs={10} sm={6} md={6} lg={3} className={classes.container}>
                <Grid container spacing={4}>
                    <Grid item xs={12} align="center">
                        <img width={isMobile? 128 : 192 } height={isMobile ? 37 : 56 }  src={`${process.env.REACT_APP_STATIC_IMAGES_URL}/logoCliente.png`} />
                    </Grid>
                    <Grid item xs={12} align="center">
                        <Typography variant="h6" style={{ fontWeight: 'bold'}}>
                            Restablecer Contraseña
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField 
                            fullWidth
                            error={touched && emailRequired}
                            inputRef={inputEmail}
                            autoFocus={true}
                            id={"email"}
                            label="Correo electrónico"
                            variant="outlined"
                            helperText={ (touched && emailRequired) ? "El campo es requerido" : "" } 
                            onChange={(event) => handleChange(event.target.value, "email")}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            fullWidth
                            variant="contained"
                            size="large"
                            color="primary"
                            aria-label="add"
                            onClick={() => handleResetPassword({ email: inputEmail.current.value })}
                            disabled={fetching}
                        >
                            { fetching ? <CircularProgress size={24} className={classes.extendedIcon} />:<ExitToApp className={classes.extendedIcon} /> }
                            Restablecer
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography style={{ color: errorMessage ? "red" : "green" }}>{ errorMessage || message }</Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.links}>
                        <Link className={classes.link} to="/signin"><Typography>Ingresar</Typography></Link>
                    </Grid>
                    <Grid item xs={12} className={classes.links}>
                        <Link className={classes.link} to="/signup"><Typography>Registrarme</Typography></Link>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ForgottenLayout