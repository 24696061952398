import React from "react";

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import FilterIcon from '@material-ui/icons/Filter';
import AddIcon from '@material-ui/icons/Add';

import { makeStyles } from '@material-ui/core/styles';

const useButtonStyles = makeStyles(theme => {
  return ({
    button:{
      marginLeft: 15,
      height: 43
    },
    circular: {
      marginRight: 12
    },
  });
});

export const ButtonOk = (props) => {

  const classes = useButtonStyles();
  const { disabled, onClick, fetching, Icon, component: Component, to, type } = props;

  return(
    <Button
      className={classes.button}
      variant="outlined"
      color="primary"
      disabled={disabled || fetching}
      component={Component}
      to={to}
      type={type}
      onClick={onClick}
    >
      { fetching ?
        <CircularProgress size={14} className={classes.circular}/> :
        Icon ? <Icon className={classes.circular} /> : <CheckIcon className={classes.circular} /> }
        { props.children }
    </Button>
  );
};

export const ButtonFilter = (props) => {

  const classes = useButtonStyles();
  const { disabled, onClick, fetching, Icon, component: Component, to, type } = props;

  return(
    <Button
      className={classes.button}
      color="secondary"
      disabled={disabled}
      component={Component}
      to={to}
      onClick={onClick}
      type={type}
    >
      { fetching ?
        <CircularProgress size={14} className={classes.circular}/> :
        ( Icon ?
        <Icon className={classes.circular} /> :
        <ClearIcon className={classes.circular} /> )
      }
      { props.children }
    </Button>
  );
};

export const ButtonCancel = (props) => {

  const classes = useButtonStyles();
  const { disabled, onClick, fetching, Icon, component: Component, to, type } = props;

  return(
    <Button
      className={classes.button}
      variant="outlined"
      color="secondary"
      disabled={disabled}
      component={Component}
      to={to}
      onClick={onClick}
      type={type}
    >
      { fetching ?
        <CircularProgress size={14} className={classes.circular}/> :
        ( Icon ?
        <Icon className={classes.circular} /> :
        <ClearIcon className={classes.circular} /> )
      }
      { props.children }
    </Button>
  );
};

export const ButtonAction = (props) => {

  const classes = useButtonStyles();
  const { disabled, onClick, fetching, Icon, component: Component, to, type, style, isActive, className } = props;

  return(
    <Button
      className={className}
      style={{ ...style }}
      size="small"
      variant={isActive ? "outlined" : "contained"}
      color="primary"
      disabled={disabled}
      component={Component}
      to={to}
      onClick={onClick}
      type={type}
    >
      { fetching ?
        <CircularProgress size={14} className={classes.circular}/> :
        Icon ? <Icon className={classes.circular} /> : <AddIcon className={classes.circular} /> }
      { props.children }
    </Button>
  );
};
