import React, { useEffect } from "react";
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({
  component: Component,
  publicComponent: PublicComponent,
  auth,
  deleteToken,
  classes,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          auth.risToken
            ? ( auth.requireChangePassword ?
              <Redirect to={{
                  pathname: '/resetpass'
                }}
              /> :
              <Component
                {...props}
                auth={auth}
                deleteToken={deleteToken}
              />) :
              ( PublicComponent ? 
                <PublicComponent 
                  classes={classes} 
                  {...props}
                /> 
                : null
              )
        );
      }}
    />
  );
};

export default PrivateRoute;
