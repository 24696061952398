import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import { 
    makeStyles, 
    Grid,
    Typography,
    TextField,
    Button,
    CircularProgress,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    OutlinedInput,
    InputAdornment,
    IconButton
} from "@material-ui/core";

import {
    Pageview,
    ExitToApp,
    Visibility as VisibilityIcon
} from "@material-ui/icons";

import { Link } from "react-router-dom";

import { requestPasswordReset, resetPassword, validateToken, signIn } from "../../actions/auth";
import { setSnack } from "../../actions/globals";

const useStyles = makeStyles((theme) => ({
    root: {
        flex: true,
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(3),
        minHeight: "82vh"
    },
    container: {
        marginTop: theme.spacing(3)
    },
    link: {
        textDecoration: "none"
    },
    links: {
        textAlign: "center"
    },
    extendedIcon: {
        marginRight: theme.spacing(2)
    }
}));

let passwordValidator = require('password-validator');

const validatePassword = (password) => {

    let schema = new passwordValidator();

    // Add properties to it
    schema
    .is().min(8)                                    // Minimum length 8
    .has().uppercase()                              // Must have uppercase letters
    .has().digits();                                // Must have digits

    let errorSchema = schema.validate(password, { list: true });

    let errorv = []

    errorSchema.map((ev) => {
        if(ev == "min") {
            errorv.push("El password debe ser de al menos 8 caracteres");
        }
        if(ev == "uppercase") {
            errorv.push("El password debe contener al menos una letra mayúscula");
        }
        if(ev == "digits") {
            errorv.push("El password debe contener al menos 2 dígitos");
        }
    });

    return errorv;

}

const ResetPassLayout = (props) => {

    const classes = useStyles();

    const dispatch = useDispatch();

    const fetching = useSelector(state => state.auth.isFetching);

	const [ errors, setErrors ] = useState({ 
        password: "El campo es requerido",
        passwordConfirmation: "El campo es requerido"
    });

	const [ passwordRequired, setPasswordRequired ] = useState(false);
    const [ passwordConfirmRequired, setPasswordConfirmRequired ] = useState(false);
    const [ passwordConfirmEqual, setPasswordConfirmEqual ] = useState(false);
    const [ userData, setUserData ] = useState({ userId: null, userName: null, token: null });
    const [ passwordType, setPasswordType ] = useState("password");
    const [ passwordTypeConfirmation, setPasswordTypeConfirmation ] = useState("password");

	const [ errorMessage, setErrorMessage ] = useState("");
	const [ message, setMessage ] = useState("");

    const [ touched, setTouched ] = useState(false);

	const inputPassword = useRef(null);

	const inputPasswordConfirmation = useRef(null);

    useEffect(() => {
        (async () => {
            if(props.match && props.match.params){
                const { token } = props.match.params;
                if(token){
                    const resp = await dispatch(validateToken(token));
                    if(resp.value.data.error){
                        props.history.push({ pathname: '/forgotten', state: { error: "Token inválido, realice el proceso de reestablecimiento de contraseña nuevamente" } });
                    } else {
                        const { id, userName, token } = resp.value.data;
                        setUserData({ userId: id, userName: userName, token: token });
                    }
                } else {
                    props.history.push({ pathname: '/forgotten', state: { error: "Token inválido, realice el proceso de reestablecimiento de contraseña nuevamente" } });
                }
            }
        })();
    }, []);

    const handleResetPassword = async (values) => {
        try {

            const { password, passswordConfirm } = values;
            
            let invalid = false;

            Object.keys(errors).map(key => {
                if(errors[key]){
                    invalid = true;
                }
            })

            if(invalid){
                setTouched(true);
            } else {
                setErrorMessage("");
                const resp = await dispatch(resetPassword({ password: password, userName: userData.userName, userId: userData.userId, token: userData.token }));
                if(resp.value.data.error){
                    props.history.push({ pathname: '/forgotten', state: { from: "reset", error: "Token inválido, realice el proceso de reestablecimiento de contraseña nuevamente" } });
                } else {
                    props.history.push({ pathname: '/signin', state: { from: "reset", message: "Se ha reestablecido correctamente su contraseña, puede iniciar su sesión" } });
                }
            }

        } catch (error) {

            setErrorMessage("Token inválido, realice el proceso de reestablecimiento de contraseña nuevamente");
        
        }

    }

    const handleChange = (value, field) => {

        let newErrors = { ...errors };

        if(field === "password"){
            if(value){
                let fails = validatePassword(value);
                if(fails.length > 0) {
                    newErrors[field] = fails[0];
                } else {
                    if(value === inputPasswordConfirmation.current.value){
                        newErrors[field] = "";
                    } else {
                        newErrors[field] = "";
                        newErrors["passwordConfirmation"] = "El campo confirmar contraseña debe ser igual al campo contraseña";
                    }
                }
            } else {
                newErrors[field] = "El campo es requerido";
            }
        } else if (field === "passwordConfirmation") {
            if(value){
                if(value === inputPassword.current.value){
                    newErrors[field] = "";
                } else {
                    newErrors[field] = "El campo confirmar contraseña debe ser igual al campo contraseña";
                }
            } else {
                newErrors[field] = "El campo es requerido";
            }
        }

        setErrors(newErrors);
        
    }

    return (
        <Grid container className={classes.root}>
            <Grid item xs={10} sm={6} md={6} lg={3} className={classes.container}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Typography color="primary" variant="h5">Restablecer Contraseña</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField 
                            fullWidth
                            type={passwordType}
                            error={touched && errors.password}
                            inputRef={inputPassword}
                            id={"password"}
                            label="Contraseña"
                            variant="outlined"
                            helperText={touched && errors.password} 
                            onChange={ (event) => handleChange(event.target.value, "password") }
                            InputProps={{
                                endAdornment: 
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => {
                                                let type;
                                                if(passwordType === "password"){
                                                    type = "text";
                                                } else {
                                                    type = "password";
                                                }
                                                setPasswordType(type);
                                            }}
                                        >
                                            <VisibilityIcon/>
                                        </IconButton>
                                    </InputAdornment>
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                    <TextField 
                            fullWidth
                            type={passwordTypeConfirmation}
                            error={touched && errors.passwordConfirmation}
                            inputRef={inputPasswordConfirmation}
                            id={"passwordConfirmation"}
                            label="Confirmar contraseña"
                            variant="outlined"
                            helperText={touched && errors.passwordConfirmation} 
                            onChange={ (event) => handleChange(event.target.value, "passwordConfirmation") }
                            InputProps={{
                                endAdornment: 
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => {
                                                let type;
                                                if(passwordTypeConfirmation === "password"){
                                                    type = "text";
                                                } else {
                                                    type = "password";
                                                }
                                                setPasswordTypeConfirmation(type);
                                            }}
                                        >
                                            <VisibilityIcon/>
                                        </IconButton>
                                    </InputAdornment>
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            fullWidth
                            variant="contained"
                            size="large"
                            color="primary"
                            aria-label="add"
                            onClick={() => handleResetPassword({ password: inputPassword.current.value, passswordConfirm: inputPasswordConfirmation.current.value })}
                            disabled={fetching}
                        >
                            { fetching ? <CircularProgress size={24} className={classes.extendedIcon} />:<ExitToApp className={classes.extendedIcon} /> }
                            Restablecer
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography style={{ color: errorMessage ? "red" : "green" }}>{ errorMessage || message }</Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.links}>
                        <Link className={classes.link} to="/signin"><Typography>Ingresar</Typography></Link>
                    </Grid>
                    <Grid item xs={12} className={classes.links}>
                        <Link className={classes.link} to="/forgotten"><Typography>Olvidé mi contraseña</Typography></Link>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ResetPassLayout