import React from "react";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

const CustomSelect = ({
  field,
  label,
  children,
  form: { touched, error },
  onChange,
  ...custom
}) => {
  return (
    <div>
      <FormControl fullWidth error={touched && !!error}>
        <InputLabel>{label}</InputLabel>
        <Select
          {...field}
          onChange={(event) => {
            if (onChange && event.target.value) {
              onChange(event);
              return field.onChange(event);
            }
            return field.onChange(event);
          }}
          {...custom}
          fullWidth
        >
          {children}
        </Select>
      </FormControl>
    </div>
  );
};

export default CustomSelect;
