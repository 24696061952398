import axios from 'axios';

export const GET_PROFESSIONALS = 'GET_PROFESSIONALS';
export const GET_PROFESSIONAL_BY_ID = 'GET_PROFESSIONAL_BY_ID';

export const getProfessionals = () => {
  return {
    type: GET_PROFESSIONALS,
    payload: axios.get(`${process.env.REACT_APP_MEDEXWARE_RIS_URL}/api/professional`)
      .then((json) => {
        return {
          professionals: json.data,
        };
      })
  };
};

export const getProfessionalById = (id) => {
  return {
    type: GET_PROFESSIONAL_BY_ID,
    payload: axios.get(`${process.env.REACT_APP_MEDEXWARE_RIS_URL}/api/professional/${id}`)
      .then((json) => {
        return {
          professional: json.data,
        };
      })
  };
};

const shouldFetchProfessionals = (state) => {
  const { professionals } = state;
  if (!professionals.items.length) {
    return true;
  }
  if (professionals.isFetching) {
    return false;
  }
  return professionals.didInvalidate;
};

export const fetchProfessionalsIfNeeded = () => {
  return (dispatch, getState) => {
    if (shouldFetchProfessionals(getState())) {
      return dispatch(getProfessionals());
    }
  };
};