import React, { useState, useEffect, useRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';

const ModalShare = (props) => {

	const { handleCloseModalShare } = props;

	const inputEmail = useRef(null);
	const [emailValidation, setEmailValidation] = useState({error: false, text:""});

	const validateForm = async (emailIn) => {

		const validateEmail = (email) => {
		    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		    return re.test(String(email).toLowerCase());
		};

		if(emailIn === "") {

			setEmailValidation({error:true, text: "El campo es requerido"});

		} else {

			if(validateEmail(emailIn)) {

				props.handleCloseModalShare(true, emailIn);
				setEmailValidation({error:false, text: ""});

			} else {

				setEmailValidation({error:true, text: "Debe escribir una dirección de correo válida"});

			}

		}

	}

	return (
	    <Dialog
	      open={props.open}
	      onClose={() => handleCloseModalShare(false)}
	      aria-labelledby="alert-dialog-title"
	      aria-describedby="alert-dialog-description"
	      maxWidth="sm"
	      fullWidth
	    >
		    <DialogTitle id="alert-dialog-title">{"Compartir resultado"}</DialogTitle>
		    <DialogContent>
		        <Grid container spacing={2}>
		          <Grid item xs={12}>
		            <TextField error={emailValidation.error}
  							   inputRef={inputEmail}
  							   autoFocus={true}
  							   id={"email_to_share"}
  							   label="Email de destinatario"
  							   variant="outlined"
							   helperText={emailValidation.text}
							   fullWidth/>
		          </Grid>
		        </Grid>
		    </DialogContent>
		    <DialogActions>
		        <Button onClick={ () => props.handleCloseModalShare(false)}>
		          Cancelar
		        </Button>
		        <Button
	                color="primary"
	                autoFocus
	                onClick={ () => validateForm(inputEmail.current.value)}
		        >
		        	{ props.fetching && <CircularProgress size={24}/> }
		          	Guardar
		        </Button>
		    </DialogActions>
		</Dialog>
	);

}

export default ModalShare;
