import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
}));

const ModalInfo = (props) => {

	const { openIn,
    			actionContent,
    			actionTitle } = props;

	const classes = useStyles();

	const [modalStyle] = useState(getModalStyle);

	const handleClose = () => {
		props.handleExternalClose();
	};

  	return (
	    <Dialog
	      open={openIn}
	      onClose={() => handleClose()}
	      aria-labelledby="alert-dialog-title"
	      aria-describedby="alert-dialog-description"
        disableBackdropClick={props.disableBackdropClick}
	    >
	      <DialogTitle id="alert-dialog-title">{ actionTitle }</DialogTitle>
	      <DialogContent>
	        <DialogContentText id="alert-dialog-description">
	          { actionContent }
	        </DialogContentText>
	      </DialogContent>
	      <DialogActions>
	        <Button type="submit"
	                color="primary"
	                autoFocus
	                onClick={() => handleClose()}
	        >
	          Aceptar
	        </Button>
	      </DialogActions>
	    </Dialog>
  	);
}

export default ModalInfo;
