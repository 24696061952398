import genericActions from "./generic.actions";

const actions = genericActions({
  modelToAction: "FOUNDER",
  modelToRoute: "founder",
  modelToState: "founders",
  axiosBaseURL: process.env.REACT_APP_MEDEXWARE_APPOINTMENT_URL,
  authToken: "appointmentToken"
});

export default actions;
