const genericReducer = ({ actions, INITIAL_STATE, customReducer }) => {
  const customR = customReducer;
  const actionsAux = actions;
  const INITIAL_STATE_AUX = INITIAL_STATE;
  const reducer = (state = INITIAL_STATE_AUX, action) => {
    const customRed = customR;
    const actionsRed = actionsAux;
    const actionTypes = actionsRed.actionTypes;
    const actionNames = Object.keys(actionTypes);
    let toReturn = null;
    for(let k in actionNames){
      if(actionNames[k] === "GET_MODEL_BY_QUERY" && action.type === `${actionTypes[actionNames[k]]}_PENDING`){
        toReturn = {
          ...state,
          isFetching: true,
          didInvalidate: false
        }
      } else if (actionNames[k] === "GET_MODEL_BY_QUERY" && action.type === `${actionTypes[actionNames[k]]}_REJECTED`){
        toReturn = {
          ...state,
          isFetching: false,
          didInvalidate: true
        }
      } else if (actionNames[k] === "GET_MODEL_BY_QUERY" && action.type === `${actionTypes[actionNames[k]]}_FULFILLED`){
        toReturn = {
          ...state,
          items: [...action.payload.models],
          isFetching: false,
          didInvalidate: false
        }
      } else if(actionNames[k] === "GET_MODEL_BY_ID" && action.type === `${actionTypes[actionNames[k]]}_PENDING`){
          toReturn = {
            ...state,
            isFetching: true,
            didInvalidate: false
          }
      } else if(actionNames[k] === "GET_MODEL_BY_ID" && action.type === `${actionTypes[actionNames[k]]}_REJECTED`){
        toReturn = {
          ...state,
          isFetching: false,
          didInvalidate: true
        }
      } else if(actionNames[k] === "GET_MODEL_BY_ID" && action.type === `${actionTypes[actionNames[k]]}_FULFILLED`){
        toReturn = {
          ...state,
          item: action.payload.model,
          isFetching: false,
          didInvalidate: false
        }
      } else if(actionNames[k] === "CREATE_MODEL" && action.type === `${actionTypes[actionNames[k]]}_PENDING`){
        toReturn = {
          ...state,
          isFetching: true,
          didInvalidate: false
        }
      } else if(actionNames[k] === "CREATE_MODEL" && action.type === `${actionTypes[actionNames[k]]}_REJECTED`){
        toReturn = {
          ...state,
          isFetching: false,
          didInvalidate: true
        }
      } else if(actionNames[k] === "CREATE_MODEL" && action.type === `${actionTypes[actionNames[k]]}_FULFILLED`){
        toReturn = {
          ...state,
          items: [ ...state.items, action.payload.model ],
          isFetching: false,
          didInvalidate: false
        }
      } else if(actionNames[k] === "UPDATE_MODEL" && action.type === `${actionTypes[actionNames[k]]}_PENDING`){
        toReturn = {
          ...state,
          isFetching: true,
          didInvalidate: false
        }
      } else if(actionNames[k] === "UPDATE_MODEL" && action.type === `${actionTypes[actionNames[k]]}_REJECTED`){
        toReturn = {
          ...state,
          isFetching: false,
          didInvalidate: true
        }
      } else if(actionNames[k] === "UPDATE_MODEL" && action.type === `${actionTypes[actionNames[k]]}_FULFILLED`){

        let newState;

        if(state.items && state.items.length){
          state.items.map((item, i) => {
            if (item._id === action.payload.model._id) {
              newState = [
                ...state.items.slice(0, i),
                action.payload.model,
                ...state.items.slice(i + 1)
              ];
            }
            return false;
          });
        } else {
          newState = [ action.payload.model ];
        }

        toReturn = {
          ...state,
          items: newState,
          isFetching: false,
          didInvalidate: false
        }
      } else if(actionNames[k] === "DELETE_MODEL" && action.type === `${actionTypes[actionNames[k]]}_PENDING`){
        toReturn = {
          ...state,
          isFetching: true,
          didInvalidate: false
        }
      } else if(actionNames[k] === "DELETE_MODEL" && action.type === `${actionTypes[actionNames[k]]}_REJECTED`){
        toReturn = {
          ...state,
          isFetching: false,
          didInvalidate: true
        }
      } else if(actionNames[k] === "DELETE_MODEL" && action.type === `${actionTypes[actionNames[k]]}_FULFILLED`){

        const newState = state.items ? state.items.filter(item => item._id !== action.payload.model._id) : [];

        toReturn = {
          ...state,
          items: newState,
          isFetching: false,
          didInvalidate: false
        }
      } else if(actionNames[k] === "RESET_MODEL" && action.type === `${actionTypes[actionNames[k]]}`){
        toReturn = {
          ...state,
          items: [],
          isFetching: false,
          didInvalidate: false
        }
      }
    }
    return toReturn ? toReturn : (customRed ? customRed(state, action) : state);
  };

  return reducer;

}

export default genericReducer;
