import React from "react";

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import CustomDialog from "./CustomDialog";
import { ButtonOk, ButtonCancel } from "./GenericElements";

const CustomPrompt = (props) => {

  const {
    okAction,
    okParams,
    open,
    title,
    content,
    handleClose
  } = props;

  const dialogTitle = <Typography variant="h5">
                        { title }
                      </Typography>

  const dialogContent = <Grid container>
                          <Grid item xs={12} style={{ marginTop: 11 }}>
                            { content }
                          </Grid>
                        </Grid>

  const actions = <React.Fragment>
                    <ButtonCancel
                      onClick={ () => handleClose() }
                    >
                      No
                    </ButtonCancel>
                    <ButtonOk
                      onClick={ () => {
                        okAction(...okParams);
                        return handleClose();
                      }}
                    >
                      Si
                    </ButtonOk>
                </React.Fragment>;

  return (
    <CustomDialog
      handleClose={handleClose}
      handleCloseParams={[]}
      open={open}
      title={dialogTitle}
      actions={actions}
      content={dialogContent}
      maxWidth="sm"
      fullWidth
      dividers={["top","bottom"]}
    />
  );
}

export default CustomPrompt;
