import {
    GET_ADMISSIONS,
    RESET_ADMISSIONS,
    GENERATE_ADMISSION_PDF,
    VERIFY_STUDY_INSTANCE_UIDS
  } from '../actions/admission';
  
  const INITIAL_STATE = {
    isFetching: false,
    didInvalidate: false,
    items: [],
    admission: {},
    lastCount: 3,
    lastQuery: "?",
    count: 0
  };
  
  const admissions = (state = INITIAL_STATE, action) => {
  
    switch (action.type) {

      case `${VERIFY_STUDY_INSTANCE_UIDS}_REJECTED`: {
        return {
          ...state,
          isFetching: false,
          didInvalidate: true
        };
      }
      case `${VERIFY_STUDY_INSTANCE_UIDS}_PENDING`: {
        return {
          ...state,
          isFetching: true,
          didInvalidate: false
        };
      }
      case `${VERIFY_STUDY_INSTANCE_UIDS}_FULFILLED`: {
        return {
          ...state,
          isFetching: false,
          didInvalidate: false
        };
      }
  
      case `${GENERATE_ADMISSION_PDF}_REJECTED`: {
        return {
          ...state,
          isFetching: false,
          didInvalidate: true
        };
      }
      case `${GENERATE_ADMISSION_PDF}_PENDING`: {
        return {
          ...state,
          isFetching: true,
          didInvalidate: false
        };
      }
      case `${GENERATE_ADMISSION_PDF}_FULFILLED`: {
        return {
          ...state,
          isFetching: false,
          didInvalidate: false
        };
      }
  
      case `${GET_ADMISSIONS}_REJECTED`:
        return {
          ...state,
          isFetching: false,
          didInvalidate: true
        };
      case `${GET_ADMISSIONS}_PENDING`:
        return {
          ...state,
          isFetching: true,
          didInvalidate: false
        };
      case `${GET_ADMISSIONS}_FULFILLED`: {
        return {
          ...state,
          isFetching: false,
          didInvalidate: false,
          items: action.payload.concat
            ? [...state.items, ...action.payload.admissions]
            : action.payload.admissions,
          lastQuery: action.payload.lastQuery,
          count: action.payload.count,
          lastCount: action.payload.resetCount ? 3 : parseInt(state.lastCount) + parseInt(action.payload.lastCount)
        };
      }
    
      case RESET_ADMISSIONS: {
        return INITIAL_STATE;
      }
    
      default:
        return state;
    }
  };
  export default admissions;
  