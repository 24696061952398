import {
    SET_SNACK
  } from '../actions/globals';
  
  const INITIAL_STATE = {
    snack: {
      open: false,
      message: "",
      severity: "success"
    }
  };

  const globals = (state = INITIAL_STATE, action) => {
    switch (action.type) {
  
    case `${SET_SNACK}`: {
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        snack: { ...action.payload.snack },
        error: null
      };
    }
  
    default:
      return state;
    }
  };
  
  export default globals;
  