
import axios from 'axios';

export const GET_MODALITYS = 'GET_MODALITYS';

export const getModalitys = () => {
    return {
      type: GET_MODALITYS,
      payload: axios.get(`${process.env.REACT_APP_MEDEXWARE_RIS_URL}/api/modality`)
        .then((json) => {
            return {
                modalitys: json.data,
            };
        })
    };
};

const shouldFetchModalitys = (state) => {
    const { modalitys, branchOffices } = state;
    if (!modalitys.items.length && !branchOffices.currentBranchOffice) {
        return true;
    }
    if (modalitys.isFetching) {
        return false;
    }
    return modalitys.didInvalidate;
};
  
export const fetchModalitysIfNeeded = () => {
    return (dispatch, getState) => {
        if (shouldFetchModalitys(getState())) {
            return dispatch(getModalitys());
        }
    };
};