import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function CustomSnackBar(props) {

  const handleClick = () => {
    props.setExternalOpen(false);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    props.setExternalOpen(false);
  };

  return (
  	<React.Fragment>
		<Snackbar
			style={{ left: "80px" }}
			anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'left',
			}}
			open={props.open}
			autoHideDuration={3000}
			onClose={handleClose}
			message={props.message+" "+props.param}
			action={
			<React.Fragment>
				<IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
				<CloseIcon fontSize="small" />
				</IconButton>
			</React.Fragment>
			}
		>
			<Alert onClose={handleClose} severity={props.severity}>
				{ props.param ? props.message+" "+props.param : props.message }
			</Alert>
		</Snackbar>
	</React.Fragment>
  );
}