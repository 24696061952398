import userActions from "../actions/user";
import genericReducer from "./generic.reducer";

const INITIAL_STATE = {
  isFetching: false,
  didInvalidate: false,
  items: [],
  user: null,
  error: null
};

const customReducer = (state, action) => {
  const actionTypes = userActions.actionTypes;
  const actionNames = Object.keys(actionTypes);
  let toReturn = null;
  for(let k in actionNames){
    if(actionNames[k] === "CHANGE_PASSWORD" && action.type === `${actionTypes[actionNames[k]]}_PENDING`){
      toReturn = {
        ...state,
        isFetching: true,
        didInvalidate: false
      }
    } else if(actionNames[k] === "CHANGE_PASSWORD" && action.type === `${actionTypes[actionNames[k]]}_REJECTED`){
      toReturn = {
        ...state,
        isFetching: false,
        didInvalidate: true
      }
    } else if(actionNames[k] === "CHANGE_PASSWORD" && action.type === `${actionTypes[actionNames[k]]}_FULFILLED`){
      toReturn = {
        ...state,
        isFetching: false,
        didInvalidate: false,
        error: null
      };
    } else if(actionNames[k] === "CHANGE_CONTACT_INFO" && action.type === `${actionTypes[actionNames[k]]}_PENDING`){
      toReturn = {
        ...state,
        isFetching: true,
        didInvalidate: false
      }
    } else if(actionNames[k] === "CHANGE_CONTACT_INFO" && action.type === `${actionTypes[actionNames[k]]}_REJECTED`){
      toReturn = {
        ...state,
        isFetching: false,
        didInvalidate: true
      }
    } else if(actionNames[k] === "CHANGE_CONTACT_INFO" && action.type === `${actionTypes[actionNames[k]]}_FULFILLED`){
      toReturn = {
        ...state,
        isFetching: false,
        didInvalidate: false,
        error: null
      };
    }
  }
  return toReturn ? toReturn : state;
}

export default genericReducer({
  actions: userActions,
  INITIAL_STATE,
  modelInput: "",
  customReducer
});
