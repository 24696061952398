import React, { useState, useRef, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import config from '../config/config';
import Pageview from '@material-ui/icons/Pageview';
import {
  useParams
} from "react-router-dom";

import { isMobile } from "react-device-detect";

import Study from './studies/Study';
import ErrorShow from './errors/ErrorShow';

function StudyLayout(props) {

	const [fetching, setFetching] = useState(false);
	const [fetchError, setfetchError] = useState(false);
	const [study, setStudy] = useState(null);
	const [errorType, setErrorType] = useState(null);

	const { classes } = props;

	const { id } = useParams();

	const studyId = id;

	const getStudy = async () => {

		try {
			let response = await axios.get(config.apiURL+"/api/study/fromemail/"+studyId);
			setStudy(response.data);
			setFetching(false);
		} catch(error) {
			console.log(error);
		}

	}

	useEffect(() => {

		if(studyId && studyId.length > 0) {

			setFetching(true);
			getStudy()

		}

	}, []);

	let StudyComponent = (study) ? <Study classes={classes} study={study} /> : null;
	let ErrorComponent = fetchError ? <ErrorShow type={errorType} classes={classes} /> : null;

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Grid container spacing={isMobile ? 1 : 3}>
					<Grid item xs={12} className={ classes.root } >
						{/* <Grid container spacing={1}>
							<Grid item xs={12} >
								<Typography variant="body1" gutterBottom align="left" style={{ marginBottom: 10 }}>
									Bienvenido al sistema de consulta de resultados.
								</Typography>
							</Grid>
						</Grid> */}
						<Grid container>
							<Grid item xs={12} className={ classes.root } >
								{
									!fetching && StudyComponent
								}
								{
									fetching && studyId && studyId.length > 0 && <CircularProgress />
								}
								{
									ErrorComponent
								}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

export default StudyLayout;
