import axios from 'axios';

export const GET_SERVICES = 'GET_SERVICES';

export const getServices = () => {
  return {
    type: GET_SERVICES,
    payload: axios.get(`${process.env.REACT_APP_MEDEXWARE_RIS_URL}/api/services`)
      .then((response) => {
        return {
          services: response.data
        };
      })
      .catch(Errr => console.log('Llega a un error acá ', Errr))
  };
};

const shouldFetchServices = (state) => {
  const { services } = state;
  if (!services.items.length) {
    return true;
  }
  if (services.isFetching) {
    return false;
  }
  return services.didInvalidate;
};

export const fetchServicesIfNeeded = () => {
  return (dispatch, getState) => {
    if (shouldFetchServices(getState())) {
      return dispatch(getServices());
    }
  };
};
