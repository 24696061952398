import axios from "axios";
import genericActions from "./generic.actions";

const GET_TURNS_BY_AGENDA_QUERY = "GET_TURNS_BY_AGENDA_QUERY";
const UPDATE_AGENDA_TURNS_STATE = "UPDATE_AGENDA_TURNS_STATE";

const getTurnsByAgendaQuery = (query) => {
  return (dispatch, getState) => {
    const state = getState();
    return dispatch({
      type: GET_TURNS_BY_AGENDA_QUERY,
      payload: axios.get(`${process.env.REACT_APP_MEDEXWARE_APPOINTMENT_URL}/api/agendaTurn/byAgenda${query}`, { headers: { Authorization: `Bearer ${state.auth["appointmentToken"]}` } })
      .then((json) => {
        return {
          models: json.data
        };
      })
    });
  }
}

const updateAgendaTurnsState = (turns, stateId) => {
  return (dispatch, getState) => {
    const state = getState();
    return dispatch({
      type: UPDATE_AGENDA_TURNS_STATE,
      payload: axios.put(`${process.env.REACT_APP_MEDEXWARE_APPOINTMENT_URL}/api/agendaTurn/updateState`, { turns, stateId }, { headers: { Authorization: `Bearer ${state.auth["appointmentToken"]}` } })
      .then((json) => {
        return {
          models: json.data
        };
      })
    });
  }
}

const actions = genericActions({
  modelToAction: "AGENDA_TURN",
  modelToRoute: "agendaTurn",
  modelToState: "agendaTurns",
  axiosBaseURL: process.env.REACT_APP_MEDEXWARE_APPOINTMENT_URL,
  authToken: "appointmentToken"
});

actions.actionTypes["UPDATE_AGENDA_TURNS_STATE"] = UPDATE_AGENDA_TURNS_STATE;
actions.actions["updateAgendaTurnsState"] = updateAgendaTurnsState;

actions.actionTypes["GET_TURNS_BY_AGENDA_QUERY"] = GET_TURNS_BY_AGENDA_QUERY;
actions.actions["getTurnsByAgendaQuery"] = getTurnsByAgendaQuery;

export default actions;