import axios from "axios";

export const DELETE_TOKEN = 'DELETE_TOKEN';
export const SIGN_IN = 'SIGN_IN';
export const REQUEST_PASSWORD_RESET = 'REQUEST_PASSWORD_RESET';
export const VERIFY_TOKEN = "VERIFY_TOKEN";
export const VALIDATE_TOKEN = 'VALIDATE_TOKEN';
export const VERIFY_MANAGEMENT_TOKEN = 'VERIFY_MANAGEMENT_TOKEN';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_AUTH_FETCHING = "RESET_AUTH_FETCHING";
export const CONFIRM_EMAIL_TOKEN = "CONFIRM_EMAIL_TOKEN";

// Verifiy token actions
export const verifyToken = () => {
  return {
    type: VERIFY_TOKEN,
    payload: axios.get(`${process.env.REACT_APP_MEDEXWARE_RIS_URL}/api/user/verify`)
    .then((resp) => {
      return {
        data: resp.data
      };
    })
  }
}

// Reset
export const resetAuthFetching = () => {
  return {
    type: RESET_AUTH_FETCHING
  }
}

// Reset password functions
export const resetPassword = (data) => {
  return {
    type: RESET_PASSWORD,
    payload: axios.put(`${process.env.REACT_APP_MEDEXWARE_RIS_URL}/api/user/resetpassword`, data)
    .then((resp) => {
      return {
        data: resp.data
      };
    })
    .catch((error) => {
      return {
        error: error
      };
    })
  }
}

// Validate token Functions
export const validateToken = (token) => {
  return {
    type: VALIDATE_TOKEN,
    payload: axios.get(`${process.env.REACT_APP_MEDEXWARE_RIS_URL}/api/user/validate/${token}`)
    .then((resp) => {
      return {
        data: resp.data
      };
    })
    .catch((error) => {
      return {
        error: error
      };
    })
  }
}

// Request password reset actions
export const requestPasswordReset = (data) => {
  return {
    type: REQUEST_PASSWORD_RESET,
    payload: axios.post(`${process.env.REACT_APP_MEDEXWARE_RIS_URL}/api/user/reqresetpass`, data)
    .then((resp) => {
      return {
        data: resp.data
      };
    })
    .catch((error) => {
      return {
        error: error
      };
    })
  }
}

// Sign In actions
export function signIn(data) {
  return {
    type: SIGN_IN,
    payload: axios.post(`${process.env.REACT_APP_MEDEXWARE_RIS_URL}/login`, data)
    .then((resp) => {
      return {
        data: resp.data
      };
    })
  }
}

export const verifyManagementToken = (token) => {
  return {
    type: VERIFY_MANAGEMENT_TOKEN,
    payload: axios.get(`${process.env.REACT_APP_MEDEXWARE_RIS_URL}/api/user/managementverify/${token}`)
    .then((resp) => {
      return {
        data: resp.data
      };
    })
  };
}

export const deleteToken = () => {
  return {
    type: DELETE_TOKEN
  };
};

export const confirmEmailToken = (token) => {
  return {
    type: CONFIRM_EMAIL_TOKEN,
    payload: axios.get(`${process.env.REACT_APP_MEDEXWARE_RIS_URL}/api/user/confirmEmail/${token}`).then((json) => {
      return {
        data: json.data
      }
    })
  }
}
