import React, { useState, useEffect } from "react";
import styled from 'styled-components';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormHelperText from '@material-ui/core/FormHelperText';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';


const Control = styled(FormControl)`
  .MuiInputLabel-shrink {
    transform: translate(13px, -5.5px) scale(0.75) !important;
  }
`;

const CustomTextField = ({
  autoFocus,
  label,
  field,
  form: { touched, errors, values },
  showError,
  labelstyle,
  shrink,
  functionKeyPress,
  isPassword,
  variant,
  ...custom
}) => {

  const error = errors[field.name];

  const [ inputType, setInputType ] = useState(null);
  const [ showPassword, setShowPassword ] = useState(false);

  useEffect(() => {
    if(isPassword){
      setShowPassword(false);
      setInputType("password");
    } else {
      setShowPassword(true);
      setInputType("text");
    }
  }, []);

  useEffect(() => {
    if(showPassword){
      setInputType("text");
    } else {
      setInputType("password");
    }
  }, [showPassword]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  if (!variant || variant === 'standard') {
    return (
      <FormControl fullWidth error={touched && !!error}>
        <InputLabel shrink={shrink} classes={{ ...labelstyle }}>{label}</InputLabel>
        <Input
          value={ field.value }
          name={ field.name }
          autoFocus={autoFocus}
          fullWidth
          {...field}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              if(functionKeyPress){
                e.preventDefault()
                return functionKeyPress(e)
              }
            }
          }}
          {...custom}
          error={touched && !!error}
        />
        {touched && !!error && showError && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    );
  } else if (variant === 'filled') {
    return (
      <FormControl fullWidth error={touched && !!error}>
        <InputLabel shrink={shrink} classes={{ ...labelstyle }}>{label}</InputLabel>
        <FilledInput
          value={ field.value }
          name={ field.name }
          autoFocus={autoFocus}
          fullWidth
          {...field}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              if(functionKeyPress){
                e.preventDefault()
                return functionKeyPress(e)
              }
            }
          }}
          {...custom}
          error={touched && !!error}
        />
        {touched && !!error && showError && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    );
  } else {
    return (
      <FormControl fullWidth error={touched && !!error} variant="outlined">
        <InputLabel htmlFor={`outlined-${field.name}`}>{ label }</InputLabel>
        <OutlinedInput
          value={ field.value }
          name={ field.name }
          id={ `outlined-${field.name}` }
          label={label}
          autoFocus={autoFocus}
          type={inputType ? inputType : field.type }
          endAdornment={
            isPassword ? <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment> : null
          }
          fullWidth
          {...field}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              if(functionKeyPress){
                e.preventDefault()
                return functionKeyPress(e)
              }
            }
          }}
          {...custom}
          error={touched && !!error}
        />
        {touched && !!error && showError && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    );
  }
};

export default CustomTextField;
