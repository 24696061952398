import React, { useState, useEffect } from 'react';
import { FormikProvider, useFormik, Field } from 'formik';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

import ClearAllIcon from '@material-ui/icons/Clear';
import FilterListIcon from '@material-ui/icons/FilterList';
import SelectAllIcon from '@material-ui/icons/SelectAll';
import Brightness5Icon from '@material-ui/icons/Brightness5';
import Brightness6Icon from '@material-ui/icons/Brightness6';

import founderActions from "../../../actions/funder";
import appointmentActions from "../../../actions/appointment";
import agendaTurnActions from "../../../actions/agendaTurn";
import appointmentStateActions from "../../../actions/appointmentState";

import { ButtonFilter } from "../../commons/GenericElements";
import CustomTextField from '../../commons/form/CustomTextField';
import CustomAutocomplete from '../../commons/form/CustomAutocomplete';
import CustomSelect from '../../commons/form/CustomSelect';

const styles = (theme) => {
  return {
    root: {
      ...theme.mixins.gutters(),
      paddingBottom: theme.spacing(2),
      paddingTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      background: '#f1f1f1',
      border: 'none'
    },
    topSpacing: {
      marginTop: theme.spacing(2)
    },
    color: {
      color: 'red',
      fontSize: 50
    },
    cssLabel: {
      '&$cssFocused': {
        color: '#333333',
      },
      color: '#333333'
    },
    cssFocused: {},
  };
};

const FilterTurnsByPatient = (props) => {

  const {
    classes
  } = props;

  const [ funderOptions, setFunderOptions ] = useState([]);
  const [ funderRender, setFunderRender ] = useState(null);
  const [ resetAuto, setResetAuto ] = useState(false);
  const [ activeButtons, setActiveButtons ] = useState({ all: false, mourning: false, afternoon: false });

  useEffect(() => {
    props.fetchFundersIfNeeded("?");
    props.fetchAppointmentStatesIfNeeded("?");
  }, []);

  const handleSubmitForm = (values, actions) => {

    const appointStates = props.appointmentStates.filter(appointmentState => (appointmentState.key === "assigned" || appointmentState.key === "confirmed")).map(appointmentState => appointmentState._id);

    let query = `?appointmentState=${appointStates}`;

    if(values){

      Object.keys(values).map((key) => {
        if (key === 'funder') {
          if (values[key]) {
            query = `${query}&founderId=${values[key].id}`;
            return query;
          }
        }
        if (key === 'patientName') {
          if (values[key]) {
            query = `${query}&patientName=${values[key]}`;
            return query;
          }
        }
        if (key === 'patientLastname') {
          if (values[key]) {
            query = `${query}&patientLastname=${values[key]}`;
            return query;
          }
        }
        if (key === 'patientDocumentType') {
          if (values[key]) {
            query = `${query}&patientDocumentType=${values[key]}`;
            return query;
          }
        }
        if (key === 'patientDocumentNumber') {
          if (values[key]) {
            query = `${query}&patientDocumentNumber=${values[key]}`;
            return query;
          }
        }
        if (key === 'patientId') {
          if (values[key]) {
            query = `${query}&patientId=${values[key]}`;
            return query;
          }
        }
        if (key === 'patientEmail') {
          if (values[key]) {
            query = `${query}&patientEmail=${values[key]}`;
            return query;
          }
        }
      });

      return props.getAppointmentsByQuery(query);

    }

    props.getAppointmentsByQuery(query);
  }

  const formik = useFormik({
    initialValues: {
      "funder": null,
      "patientName": "",
      "patientLastname": "",
      "patientDocumentType": "",
      "patientDocumentNumber": "",
      "patientEmail": "",
      "patientId": ""
    },
    onSubmit: handleSubmitForm
  })

  const clearForm = () => {
    setResetAuto(true)
    formik.resetForm();
    props.resetAppointments();
    props.resetTurns();
  }

  useEffect(() => {
    if(props.funders && props.funders.length){
      setFunderOptions(props.funders.filter(funder => funder.status === "ACTIVO").map(funder => ({ value: { id: funder._id, name: funder.name }, label: funder.name })))
    }
  }, [props.funders]);

  const clearReset = () => setResetAuto(false);

  return (
    <Grid className={classes.root}>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={12} sm={12} md={4}>
                <Field
                  name="patientId"
                  component={CustomTextField}
                  label="HC"
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Field
                  name="patientName"
                  component={CustomTextField}
                  label="Nombre"
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Field
                  name="patientLastname"
                  component={CustomTextField}
                  label="Apellido"
                  type="text"
                />
              </Grid>
              <Grid item xs={4} sm={4} md={1}>
                <Field
                  name="patientDocumentType"
                  component={CustomSelect}
                  label="Tipo Doc."
                >
                  <MenuItem value="DNI">DNI</MenuItem>
                  <MenuItem value="PAS">PAS</MenuItem>
                </Field>
              </Grid>
              <Grid item xs={8} sm={8} md={3}>
                <Field
                  name="patientDocumentNumber"
                  component={CustomTextField}
                  label="N° de documento"
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Field
                  name="funder"
                  component={CustomAutocomplete}
                  label="Financiador"
                  options={funderOptions}
                  valuesRender={funderRender}
                  resetAuto={resetAuto}
                  clearReset={clearReset}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Field
                  name="patientEmail"
                  component={CustomTextField}
                  label="Email"
                  type="text"
                />
              </Grid>
            </Grid>
            <Grid
              container
              className={classes.topSpacing}
              item xs={12}
              align="right"
              justify="flex-end"
            >
              <ButtonFilter
                Icon={ClearAllIcon}
                onClick={() => clearForm()}
                isFilter
              >
                Limpiar
              </ButtonFilter>
              <ButtonFilter
                type="submit"
                disabled={props.fetching || !formik.isValid}
                fetching={props.fetching}
                Icon={FilterListIcon}
                isFilter
              >
                Filtrar
              </ButtonFilter>
            </Grid>
          </Grid>
        </form>
      </FormikProvider>
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    fetching: state.agendaTurns.isFetching || state.founders.isFetching || state.appointments.isFetching,
    agendaTurns: state.agendaTurns.items,
    funders: state.founders.items,
    appointments: state.appointments.items,
    appointmentStates: state.appointmentStates.items
  };
};

const mapDispatchToProps = (dispatch) => {
  const {
    fetchModelsIfNeeded: fetchFundersIfNeeded
  } = founderActions.actions;

  const {
    fetchModelsIfNeeded: fetchAppointmentStatesIfNeeded
  } = appointmentStateActions.actions;

  const {
    getModelByQuery: getAppointmentsByQuery,
    resetModel: resetAppointments
  } = appointmentActions.actions;

  const {
    resetModel: resetTurns
  } = agendaTurnActions.actions;

  return bindActionCreators({
    getAppointmentsByQuery,
    fetchAppointmentStatesIfNeeded,
    fetchFundersIfNeeded,
    resetAppointments,
    resetTurns
  }, dispatch);
};

const FilterWithStyles = withStyles(styles)(FilterTurnsByPatient);

export default connect(mapStateToProps, mapDispatchToProps)(FilterWithStyles);
